import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  BackgroundModal,
  ModalBoxCliente,
  ModalHeader,
  X,
} from '../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { IoMdClose } from 'react-icons/io'
import Loading from '../../../Loading/Loading'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ButtonSalvarSenha } from '../../../Home/HomeRoutes/Perfil/styledPerfil'
import { FunilWrapper } from '../../Funis/styledFunis'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'

function Novo({ openModal }) {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [loading, setLoading] = useState(false)
  const [nomeTipoTarefa, setTipoTarefa] = useState('')
  const [erroNome, setErroNome] = useState(false)

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }

    return () => {
      document.body.classList.remove('no-scroll')
    }
  }, [openModal])

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  // loading, toast, erro geral, erro nome existente
  async function criarTarefa() {
    try {
      const data = await parametrosRepository.criarTarefa(nomeTipoTarefa)

      console.log('CRIAR TAREFA:', data)
      feedback('Tarefa criada com sucesso!', 'success')
      setTimeout(function () {
        setLoading(false)
      }, 1000)
      if (!loading) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error.message)
      const { data } = error.response
      if (data.statusCode === 400) {
        setErroNome(true)
      } else {
        feedback('Algo deu errado!', 'error')
      }
      setTimeout(function () {
        setLoading(false)
      }, 1000)
    }
    // await axios
    //   .post(
    //     'https://kaminocrm.com.br:7778/parametros/criarTarefa',
    //     { TAREFA_NOME: nomeTipoTarefa },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${JWT_TOKEN}`,
    //         user_login: LOGIN,
    //         ass_cod: ASS_COD,
    //       },
    //     },
    //   )
    //   .then((res) => {
    //     console.log(res)
    //     feedback('Tarefa criada com sucesso!', 'success')
    //     setTimeout(function () {
    //       setLoading(false)
    //     }, 1000)
    //     if (!loading) {
    //       window.location.reload()
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err.message)
    //     const { data } = err.response
    //     if (data.statusCode === 400) {
    //       setErroNome(true)
    //     } else {
    //       feedback('Algo deu errado!', 'error')
    //     }
    //     setTimeout(function () {
    //       setLoading(false)
    //     }, 1000)
    //   })
  }

  return (
    <BackgroundModal>
      <ToastContainer />
      {loading && <Loading />}
      <ModalBoxCliente>
        <ModalHeader>
          <h2>Criar tipo de tarefa</h2>
          <X onClick={() => openModal(false)}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>
        <FunilWrapper>
          <div className="novo_funil">
            <label htmlFor="origem">Nome do tipo</label>
            <input
              type="text"
              name="origem"
              id="origem"
              style={{ width: '100%', boxSizing: 'border-box' }}
              value={nomeTipoTarefa}
              onChange={(e) => {
                setTipoTarefa(e.target.value)
              }}
              onFocus={() => {
                if (erroNome) {
                  setErroNome(false)
                }
              }}
            />
            {erroNome ? (
              <span
                style={{ color: 'red', fontSize: '12px', fontWeight: '600' }}
              >
                Nome já utilizado!
              </span>
            ) : (
              ''
            )}
            <div className="novo_funil-footer">
              <ButtonSalvarSenha
                onClick={async () => {
                  await criarTarefa()
                  setLoading(true)
                }}
                disabled={nomeTipoTarefa.length === 0 ? true : false}
              >
                Salvar
              </ButtonSalvarSenha>
            </div>
          </div>
        </FunilWrapper>
      </ModalBoxCliente>
    </BackgroundModal>
  )
}

export default Novo
