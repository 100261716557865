import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {
  Table,
  CardTable,
  CardTableHeader,
  TableSearch,
  TableSearchButton,
  SearchWrapper,
  TableButton,
} from '../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import { MdUnarchive, MdEdit } from 'react-icons/md'
import { AuthContext } from '../../context/auth'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { FaPlus, FaSearch } from 'react-icons/fa'
import { Container } from '../Login/style/styledLogin'
import CadastroUsuarios from './CadastroUsuarios/CadastroUsuarios'
import EditarUsuario from './EditarUsuario/EditarUsuario'
import ConfirmarDesarquivar from './ModalArquivar/ConfirmarDesarquivar'
import { COLORS } from '../../colors'
import Loading from '../Loading/Loading'
import { BtnSubmit } from '../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import ReactPaginate from 'react-paginate'
import { userRepository } from '../../api/repositories/userRepository'

const Usuarios = () => {
  const { logout, PER_ID, versaoSistema, USR_NOME, ip } =
    useContext(AuthContext)
  const handleLogout = () => {
    logout()
  }

  // NAVBAR
  const [extenderNav, setExtenderNav] = useState(false)
  const [config, setConfig] = useState(false)

  // LOADING
  const [loading, setLoading] = useState(true)

  // MODAL
  const [openModal, setOpenModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  // TABELA
  const [listaUsuarios, setListaUsuarios] = useState([])
  const [pesquisa, setPesquisa] = useState('')

  // DADOS
  const [idUser, setIdUser] = useState({})
  const [loginUser, setLoginUser] = useState({})
  const [usrStatus, setUsrStatus] = useState(Boolean)

  // PAGINAÇÃO
  const [paginaAtual, setPaginaAtual] = useState(0)
  const itensPorPagina = 25

  const pageCount = Math.ceil(
    listaUsuarios.filter((i) =>
      i.USR_NOME.toLowerCase().includes(pesquisa.toLowerCase()),
    ).length / itensPorPagina,
  )

  const mudarPagina = ({ selected }) => {
    setPaginaAtual(selected)
  }

  const navigate = useNavigate()

  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')
  const USR_ID = sessionStorage.getItem('USR_ID')

  function alteracaoFeita() {
    toast.success('Usuário alterado com sucesso!', {
      position: 'top-right',
      autoClose: 700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })

    window.location.reload()
  }

  useEffect(() => {
    const buscarUsuarios = async () => {
      try {
        const usuarios = await userRepository.buscarUsuarios()
        setListaUsuarios(usuarios)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    buscarUsuarios()

    /*
    axios
      .get('https://kaminocrm.com.br:5001/usuario/buscarUsuarios', {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        console.log(res)
        setListaUsuarios(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
    */
  }, [])

  function checarStatus(CLI_STATUS) {
    if (CLI_STATUS) {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.pendente}`,
            padding: '2px 10px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ATIVO
        </span>
      )
    } else {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.perdida}`,
            padding: '2px 7px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ARQUIVADO
        </span>
      )
    }
  }

  const currentPage = listaUsuarios
    .filter((i) => i.USR_NOME.toLowerCase().includes(pesquisa.toLowerCase()))
    .slice(paginaAtual * itensPorPagina, (paginaAtual + 1) * itensPorPagina)

  return (
    <Container>
      {PER_ID ? (
        <>
          {loading ? <Loading /> : null}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '20px',
              marginTop: '-15px ',
            }}
          >
            <h2>Usuários</h2>
            <BtnSubmit
              to="cadastro-usuarios"
              onClick={() => {
                setOpenModal(true)
              }}
              style={{ padding: '7px 10px' }}
            >
              <FaPlus size={11} /> Criar um novo usuário
            </BtnSubmit>
          </div>
          {openEditModal && (
            <EditarUsuario
              closeModal={setOpenEditModal}
              USR_ID={idUser}
              alteracaoFeita={alteracaoFeita}
            />
          )}
          {openModal && <CadastroUsuarios closeModal={setOpenModal} />}
          {openConfirmModal && (
            <ConfirmarDesarquivar
              closeModal={setOpenConfirmModal}
              USR_ID={idUser}
              USR_ATIVO={true}
              USR_LOGIN={loginUser}
              alteracaoFeita={alteracaoFeita}
            />
          )}

          <>
            <div
              style={{
                padding: '0px 20px 4em 20px',
              }}
            >
              <CardTable>
                <CardTableHeader>
                  <div />
                  <SearchWrapper>
                    <TableSearch
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Pesquisa..."
                      onChange={(e) => {
                        setPesquisa(e.target.value)
                      }}
                    />
                    <TableSearchButton>
                      <FaSearch />
                    </TableSearchButton>
                  </SearchWrapper>
                </CardTableHeader>
                <Table>
                  <thead>
                    <tr>
                      <th>Nome de usuário</th>
                      <th>Login</th>
                      <th>Tipo</th>
                      <th style={{ width: '15%', textAlign: 'center' }}>
                        Status
                      </th>
                      <th style={{ width: '7%', textAlign: 'center' }}>
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPage.map((item) => {
                      if (item.USR_ID !== USR_ID) {
                        return (
                          <tr key={item.USR_ID}>
                            <td>{item.USR_NOME}</td>
                            <td>{item.USR_LOGIN}</td>
                            <td>{item.PER_ID ? 'Master' : 'Vendedor'}</td>
                            <td style={{ width: '15%', textAlign: 'center' }}>
                              {checarStatus(item.USR_ATIVO)}
                            </td>
                            <td style={{ width: '7%', textAlign: 'center' }}>
                              {item.USR_ATIVO ? (
                                <Tippy content="Editar usuário" delay="275">
                                  <TableButton
                                    type="button"
                                    onClick={() => {
                                      setOpenEditModal(true)
                                      setIdUser(item.USR_ID)
                                      window.scrollTo(0, 0)
                                    }}
                                  >
                                    <MdEdit size={20} />
                                  </TableButton>
                                </Tippy>
                              ) : (
                                <Tippy
                                  content="Desarquivar usuário"
                                  delay="275"
                                >
                                  <TableButton
                                    type="button"
                                    onClick={() => {
                                      setUsrStatus(true)
                                      setIdUser(item.USR_ID)
                                      setLoginUser(item.USR_LOGIN)
                                      setOpenConfirmModal(true)
                                      window.scrollTo(0, 0)
                                    }}
                                  >
                                    <MdUnarchive size={22} />
                                  </TableButton>
                                </Tippy>
                              )}
                            </td>
                          </tr>
                        )
                      } else {
                        return null
                      }
                    })}
                  </tbody>
                </Table>
              </CardTable>
            </div>
            <ReactPaginate
              previousLabel={<IoIosArrowBack />}
              nextLabel={<IoIosArrowForward />}
              pageCount={pageCount}
              onPageChange={mudarPagina}
              containerClassName={'paginationContainer'}
              pageLinkClassName={'Buttons'}
              previousLinkClassName={'AnteriorBttn'}
              nextLinkClassName={'ProximoBttn'}
              disabledClassName={'paginationDisabled'}
              activeClassName={'paginationActive'}
            />
          </>
        </>
      ) : (
        navigate('/')
      )}
    </Container>
  )
}

export default Usuarios
