import React, { useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import DraggableItem from './DraggableItem'
import {
  HiMiniBarsArrowUp,
  HiMiniBarsArrowDown,
  HiChevronDown,
  HiChevronUp,
} from 'react-icons/hi2'

const DroppableWrapper = ({
  data,
  keyDrop,
  setColunas,
  OptionsButton,
  setOpenModal,
  setDataResumo,
  mask,
}) => {
  const itemsPerPage = 3
  const [btn, setBtn] = useState(false)
  const [itemsCount, setItemsCount] = useState(itemsPerPage)

  return (
    <div className="column" key={keyDrop}>
      <div className="top">
        <span>
          <div
            className="cor_categoria"
            style={{ backgroundColor: data.cor }}
          />
          <div className="nome_categoria">{data.titulo}</div>
        </span>

        <button
          className="btn__order"
          onClick={() => {
            setBtn(!btn)
            setColunas((prev) => ({
              ...prev,
              [data.titulo.toUpperCase()]: {
                titulo: data.titulo,
                cor: data.cor,
                valorplanos: data.valorplanos,
                itens: data.itens.reverse(),
              },
            }))
          }}
        >
          {btn ? (
            <HiMiniBarsArrowDown size={21} />
          ) : (
            <HiMiniBarsArrowUp size={21} />
          )}
        </button>
      </div>

      <div className="header_values">
        <span>
          {data.itens.length > 0
            ? `${data.itens.length} ${
                data.itens.length !== 1 ? 'negociações' : 'negociação'
              }`
            : '0 negociações'}
        </span>
        <span>{data.valorplanos ? data.valorplanos : 'R$ 0,00'}</span>
      </div>

      <Droppable droppableId={`${keyDrop}`}>
        {(provided, snapshot) => {
          return (
            <>
              <div
                className="card_container"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {data.itens.slice(0, itemsCount).map((el, index) => {
                  return (
                    <DraggableItem
                      el={el}
                      index={index}
                      OptionsButton={OptionsButton}
                      setOpenModal={setOpenModal}
                      setDataResumo={setDataResumo}
                      mask={mask}
                    />
                  )
                })}
                {provided.placeholder}
              </div>
              <div style={{ padding: '0.5rem' }}>
                {itemsCount < data.itens.length ? (
                  <HiChevronDown
                    size={21}
                    className="buttonDownItens"
                    onClick={() =>
                      setItemsCount((state) => state + itemsPerPage)
                    }
                  />
                ) : (
                  data.itens.length > 3 && (
                    <HiChevronUp
                      size={21}
                      className="buttonDownItens"
                      onClick={() => setItemsCount(itemsPerPage)}
                    />
                  )
                )}
              </div>
            </>
          )
        }}
      </Droppable>
    </div>
  )
}

export default DroppableWrapper
