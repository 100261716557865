// import axios from "axios";
import React, { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import {
  BackgroundModal,
  ModalBoxConfirmar,
  ModalHeader,
  X,
} from '../../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { ButtonSalvarSenha } from '../../../../Home/HomeRoutes/Perfil/styledPerfil'
// import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
// import Loading from "../../../Loading/Loading";

function ConfirmarDesarquivar({
  openArchiveModal,
  handleArchive,
  filId,
  setFilId,
}) {
  return (
    <BackgroundModal>
      {/* {loading && <Loading />} */}
      <ModalBoxConfirmar>
        <ModalHeader>
          <h2>Deseja desarquivar?</h2>
          <X
            onClick={() => {
              openArchiveModal(false)
              setFilId('')
            }}
          >
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        <div className="modalBody">
          <p>
            Deseja desarquivar? Com essa ação, vendedores registrados podem usar
            este desconto.
          </p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '5px',
              gap: '0.5rem',
            }}
          >
            <button
              className="btnClose"
              style={{
                padding: '7px 25px',
                fontSize: '14px',
                marginBottom: '0',
              }}
              onClick={() => {
                openArchiveModal(false)
                setFilId('')
              }}
            >
              Cancelar
            </button>
            <ButtonSalvarSenha
              style={{ marginBottom: '0' }}
              onClick={() => {
                handleArchive(filId)
              }}
            >
              Desarquivar
            </ButtonSalvarSenha>
          </div>
        </div>
      </ModalBoxConfirmar>
    </BackgroundModal>
  )
}

export default ConfirmarDesarquivar
