import React, { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import {
  BackgroundModal,
  ModalBoxCliente,
  ModalHeader,
  X,
} from '../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../../Loading/Loading'
import axios from 'axios'
import { ButtonEncerrar } from '../../../Home/HomeRoutes/Oportunidades/Data Table/ResumoTable/styledResumoTable'
import { ButtonSalvarSenha } from '../../../Home/HomeRoutes/Perfil/styledPerfil'
import { FunilWrapper } from '../../Funis/styledFunis'
import ConfirmarArquivar from '../ConfirmarArquivar/ConfirmarArquivar'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'

function ModalAlterar({ openModal, ORG_ID, ORG_NOME }) {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [loading, setLoading] = useState(false)
  const [novoNomeOrigem, setNovoNomeOrigem] = useState(ORG_NOME)
  const [modalArquivar, setModalArquivar] = useState(false)

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  async function editarNomeTipoTarefa() {
    try {
      const data = await parametrosRepository.atualizarOrigem(
        novoNomeOrigem,
        ORG_ID,
      )

      console.log('ATUALIZAR ORIGEM', data)
      feedback('Origem atualizada com sucesso!', 'success')
      setTimeout(function () {
        setLoading(false)
      }, 1800)
      if (!loading) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
      feedback('Ops! Algo deu errado.', 'error')
      setLoading(false)
    }

    // axios
    //   .post(
    //     'https://kaminocrm.com.br:7778/parametros/atualizarOrigem',
    //     { ORG_DESCRICAO: novoNomeOrigem, ORG_ID: ORG_ID },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${JWT_TOKEN}`,
    //         user_login: LOGIN,
    //         ass_cod: ASS_COD,
    //       },
    //     },
    //   )
    //   .then((res) => {
    //     console.log(res)
    //     feedback('Origem atualizada com sucesso!', 'success')
    //     setTimeout(function () {
    //       setLoading(false)
    //     }, 1800)
    //     if (!loading) {
    //       window.location.reload()
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     feedback('Ops! Algo deu errado.', 'error')
    //     setLoading(false)
    //   })
  }

  async function arquivarTipoTarefa() {
    try {
      const data =
        await parametrosRepository.arquivarOuDesarquivarOrigem(ORG_ID)

      console.log('ARQUIVAR TIPO TAREFA', data)
      feedback('Origem arquivada com sucesso', 'success')
      setTimeout(function () {
        setLoading(false)
      }, 1800)
      if (!loading) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
      feedback('Ops! Algo deu errado.', 'error')
      setLoading(false)
    }

    // axios
    //   .post(
    //     'https://kaminocrm.com.br:7778/parametros/arquivarOuDesarquivarOrigem',
    //     { ORG_ID: ORG_ID },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${JWT_TOKEN}`,
    //         user_login: LOGIN,
    //         ass_cod: ASS_COD,
    //       },
    //     },
    //   )
    //   .then((res) => {
    //     console.log(res)
    //     feedback('Origem arquivada com sucesso', 'success')
    //     setTimeout(function () {
    //       setLoading(false)
    //     }, 1800)
    //     if (!loading) {
    //       window.location.reload()
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     feedback('Ops! Algo deu errado.', 'error')
    //     setLoading(false)
    //   })
  }

  return (
    <BackgroundModal>
      <ToastContainer />
      {loading && <Loading />}
      {modalArquivar && (
        <ConfirmarArquivar openModal={setModalArquivar} ORG_ID={ORG_ID} />
      )}

      <ModalBoxCliente>
        <ModalHeader>
          <h2>Editar Origem</h2>
          <X onClick={() => openModal(false)}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>
        <div className="modalBody">
          <FunilWrapper>
            <div className="novo_funil">
              <label htmlFor="novo_nome_origem">Renomear Origem</label>
              <input
                type="text"
                name="novo_nome_origem"
                id="novo_nome_origem"
                value={novoNomeOrigem}
                onChange={(e) => {
                  setNovoNomeOrigem(e.target.value)
                }}
              />
              <div className="novo_funil-footer">
                <ButtonEncerrar
                  onClick={() => {
                    // arquivarTipoTarefa();
                    // setLoading(true);
                    setModalArquivar(true)
                  }}
                >
                  Arquivar tipo
                </ButtonEncerrar>
                <ButtonSalvarSenha
                  onClick={() => {
                    setLoading(true)
                    editarNomeTipoTarefa()
                  }}
                >
                  Salvar alterações
                </ButtonSalvarSenha>
              </div>
            </div>
          </FunilWrapper>
        </div>
      </ModalBoxCliente>
    </BackgroundModal>
  )
}

export default ModalAlterar
