import React, { useState, useContext, useEffect } from 'react'
import Select from 'react-select'
import { IoMdClose } from 'react-icons/io'
import { BackgroundModal, X } from '../Modal/style/styledModal'
import { AuthContext } from '../../../../../context/auth'
import './style.css'

function FilterModal({
  setFilterModal,
  funis,
  filterStatus,
  setFilterStatus,
  setFunilNome,
}) {
  const { FUN_ID, setFUN_ID } = useContext(AuthContext)
  const [funilSelecionado, setFunilSelecionado] = useState({})
  const [statusSelecionado, setStatusSelecionado] = useState({})

  const status = [
    {
      value: 'TODAS',
      label: 'TODAS',
    },
    {
      value: 'PENDENTE',
      label: 'PENDENTES',
    },
    {
      value: 'PERDIDA',
      label: 'PERDIDAS',
    },
    {
      value: 'CONVERTENDO',
      label: 'CONVERTENDO',
    },
    {
      value: 'CONVERTIDA',
      label: 'CONVERTIDAS',
    },
  ]

  useEffect(() => {
    const fun = funis.find(function (funil) {
      return funil.value === FUN_ID
    })

    const st = status.find(function (statuses) {
      return statuses.value === filterStatus
    })

    if (FUN_ID) {
      setFunilSelecionado(fun)
    }
    if (filterStatus) {
      setStatusSelecionado(st)
    }
  }, [])

  return (
    <BackgroundModal>
      <div className="Modal__Container">
        <div className="Modal__Header">
          <h2>Filtro Personalizado</h2>
          <X onClick={() => setFilterModal(false)}>
            <IoMdClose size={17} />
          </X>
        </div>

        <div className="Modal__Body">
          <div className="Filter__Item">
            <span className="Title">Por Funil</span>
            <Select
              value={funilSelecionado}
              options={funis}
              placeholder="Selecione..."
              onChange={setFunilSelecionado}
            />
          </div>

          <div className="Filter__Item">
            <div className="Title">Por Status</div>
            <Select
              value={statusSelecionado}
              options={status}
              placeholder="Selecione..."
              onChange={setStatusSelecionado}
            />
          </div>
        </div>

        <div className="Modal__Footer">
          <button
            className="BtnSubmit__Filter"
            disabled={!funilSelecionado || !statusSelecionado}
            onClick={() => {
              setFUN_ID(funilSelecionado.value)
              setFilterStatus(statusSelecionado.value)
              setFunilNome(funilSelecionado.label)
              setFilterModal(false)
            }}
          >
            Filtrar
          </button>
        </div>
      </div>
    </BackgroundModal>
  )
}

export default FilterModal
