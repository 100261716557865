import React from 'react'
import CardSelectTemplate from '../../../../../../components/CardSelectTemplate.jsx/CardSelectTemplate'

function TypesList({ tiposTemplates, template, setTemplate }) {
  const templateContents = {
    SMS: {
      icon: 'sms',
      title: 'SMS',
      content: 'Selecione essa opção para criar um modelo de SMS',
    },
    'E-MAIL': {
      icon: 'email',
      title: 'E-MAIL',
      content: 'Selecione essa opção para criar um modelo de E-mail',
    },
    WHATSAPP: {
      icon: 'whatsapp',
      title: 'WHATSAPP',
      content: 'Selecione essa opção para criar um modelo de Whatsapp',
    },
  }

  return (
    <div>
      <span style={{ fontSize: '1rem' }}>
        Selecione abaixo o Tipo do Modelo
      </span>
      <div className="templates_list">
        {tiposTemplates.map((el) => {
          return (
            <CardSelectTemplate
              cardContent={templateContents[el.TIPO_NAME]}
              tipoId={el.TIPO_ID}
              template={template}
              setTemplate={setTemplate}
              key={el.TIPO_ID}
            />
          )
        })}
      </div>
    </div>
  )
}

export default TypesList
