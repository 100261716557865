export const ENDPOINTS = {
  USUARIOS: {
    BUSCAR: '/usuario/buscarUsuarios',
    BUSCAR_ID: '/usuario/buscarUsuario',
    UPDATE: '/usuario/updateUsuario',
    TROCAR_SENHA: '/usuario/trocarSenha',
    CADASTRAR: '/usuario/cadastrar',
    UPDATE_USUARIO_V2: '/usuario/updateUsuarioV2',
    ARQUIVAR_USUARIO: '/usuario/arquivarUsuario',
  },
  FIPE: {
    CONSULTAR_PLACA: '/fipe',
    CONSULTAR_MARCAS: '/fipe/ConsultarMarcas',
    CONSULTAR_MODELOS: '/fipe/ConsultarModelos',
    CONSULTAR_ANO_MODELO: '/fipe/ConsultarAnoModelo',
    CONSULTAR_VALOR_TODOS_PARAMETROS: '/fipe/ConsultarValorComTodosParametros',
  },
  PARAMETROS: {
    LISTAR_VENDEDORES: '/parametros/listarVendedores',
    LISTAR_ORIGENS: '/parametros/listarOrigens',
    CONFIGURAR_VISTORIA: '/parametros/configurarVistoria',
    LISTAR_TAREFAS: '/parametros/listarTarefas',
    LISTAR_TABELA_COTA_PARTICIPACAO:
      '/parametros/listarTabelaDeCotaDeParticipacao',
    BUSCAR_PARAMETROS: '/parametros/buscarParametros',
    BUSCAR_IMAGENS_SISTEMA: '/parametros/buscarImagensDoSistema',
    LISTAR_AUTOMACAO: '/parametros/listarAutomacoes',
    LISTAR_MENSAGEM_TEMPLATES: '/parametros/listarMensagemTemplates',
    LISTAR_FUNIS: '/parametros/listarFunis',
    LISTAR_TABELAS_FILIACAO: '/parametros/listarTabelasDeFiliacao',
    BUSCAR_TIPOS_MENSAGENS: '/parametros/buscarTiposDeMensagens/',
    BUSCAR_TAGS_TEMPLATE: '/parametros/buscarTagsTemplate/',
    LISTAR_TABELA_MENSALIDADE: '/parametros/listarTabelaDeMensalidade',
    BUSCAR_TABELA_MENSALIDADE: '/parametros/buscarTabelaDeMensalidade',
    BUSCAR_DADOS_TABELA_FILIACAO: '/parametros/buscarDadosDaTabelasDeFiliacao',
    BUSCAR_TABELA_COTA_PARTICIPACAO:
      '/parametros/buscarTabelaDeCotaDeParticipacao',
    BUSCAR_DADOS_FUNIL: '/parametros/buscarDadosFunil',
    ATUALIZAR_PARAMETROS: '/parametros/atualizarParametros',
    CONFIGURAR_CAPA_ASSOCIACAO: '/parametros/configurarCapaAssociacao',
    CONFIGURAR_CONTRA_CAPA_ASSOCIACAO:
      '/parametros/configurarContraCapaAssociacao',
    ARQUIVAR_AUTOMACOES: '/parametros/arquivarAutomacoes',
    EDITAR_AUTOMACAO: '/parametros/editarAutomacao/',
    CRIAR_AUTOMACAO: '/parametros/criarAutomacao/',
    ATUALIZAR_ORDEM_FUNIL: '/parametros/atualizarOrdemFunil',
    ARQUIVAR_OU_DESARQUIVAR_FUNIL: '/parametros/arquivarOuDesarquivarFunil',
    ATUALIZAR_FUNIL: '/parametros/atualizarFunil',
    CRIAR_FUNIL: '/parametros/criarFunil',
    ARQUIVAR_OU_DESARQUIVAR_ORIGEM: '/parametros/arquivarOuDesarquivarOrigem',
    ATUALIZAR_ORIGEM: '/parametros/atualizarOrigem',
    CRIAR_ORIGEM: '/parametros/criarOrigem',
    ARQUIVAR_E_DESARQUIVAR_COTA_PARTICIPACAO:
      '/parametros/arquivarEDesarquivarCotaDeParticipacao',
    CRIAR_TABELA_COTA_PARTICIPACAO:
      '/parametros/criarTabelaDeCotaDeParticipacao',
    EDITAR_TABELA_COTA_PARTICIPACAO:
      '/parametros/editarTabelaDeCotaDeParticiapcao',
    ARQUIVAR_E_DESARQUIVAR_TABELA_FILIACAO:
      '/parametros/arquivarEDesarquivarTabelaDeFiliacao',
    CRIAR_TABELA_FILIACAO: '/parametros/criarTabelaDeFiliacao',
    EDITAR_TABELA_FILIACAO: '/parametros/editarTabelaDeFiliacao',
    ARQUIVAR_E_DESARQUIVAR_TABELA_MENSALIDADE:
      '/parametros/arquivarEDesarquivarTabelaDeMensalidade',
    CRIAR_TABELA_MENSALIDADE: '/parametros/criarTabelaDeMensalidade',
    EDITAR_TABELA_MENSALIDADE: '/parametros/editarTabelaDeMensalidade',
    CRIAR_TAREFA: '/parametros/criarTarefa',
    ARQUIVAR_OU_DESARQUIVAR_TAREFA: '/parametros/arquivarOuDesarquivarTarefa',
    ATUALIZAR_TAREFA: '/parametros/atualizarTarefa',
    ARQUIVAR_OU_DESARQUIVAR_MENSAGEM:
      '/parametros/arquivarOuDesarquivarMensagem',
    EDITAR_MENSAGEM: '/parametros/editarMensagem',
    CRIAR_MENSAGEM: '/parametros/criarMensagem',
    CONFIGURAR_VISTORIA_ICON: '/parametros/configurarVistoria',
  },
  FILTRO: {
    BUSCAR_FILTRO: '/filtro/buscarFitlros',
  },
  OPORTUNIDADE: {
    LISTAR_TIPOS_TAREFA: '/oportunidades/listarTiposTarefas',
    LISTAR_TIPOS_ORIGENS: '/oportunidades/listarTiposOrigens/',
    LISTAR_OPORTUNIDADES_KANBAN: '/oportunidades/listar-oportunidades-kanban',
    LISTAR_FUNIS_OPORTUNIDADES: '/oportunidades/listarFunisOportunidade',
    LISTAR_OPORTUNIDADES: '/oportunidades/listar-oportunidades',
    DASHBOARD_OPORTUNIDADES: '/oportunidades/dashboard-oportunidades',
    LISTAR_OPORTUNIDADES_OPOID: '/oportunidades/listar-oportunidade',
    VERIFICAR_DESCONTO_VENDEDOR:
      '/oportunidades/validarSeOVendedorTemDescontos',
    VALIDAR_VALOR_FILIACAO_DESCONTO:
      '/oportunidades/validarValorFiliacaoDesconto',
    VALIDAR_VALOR_COTA_PARTICIPACAO_DESCONTO:
      '/oportunidades/validarValorCotaDeParticipacaoService',
    VALIDAR_VALOR_MENSALIDADE_DESCONTO:
      '/oportunidades/validarValorMensalidade',
    CONVERTER_OPORTUNIDADE: '/oportunidades/converterOportunidade',
    CRIAR_TAREFA_OPORTUNIDADE: '/oportunidades/criarTarefaOportunidade',
    REGISTRAR_TAREFA_REALIZADA: '/oportunidades/registarTarefaRealizada',
    ATUALIZAR_OPORTUNIDADE_STATUS:
      '/oportunidades/atualizarOportunidadeStatus/',
    UPDATE_OPORTUNIDADE: '/oportunidades/update-oportunidade',
    CRIAR_OPORTUNIDADE: '/oportunidades/criar-oportunidade',
  },
  VENDEDOR: {
    BUSCAR_VENDEDORES: '/vendedores/buscarVendedores',
  },
  VEICULOS: {
    BUSCAR_TIPOS_VEICULOS: '/veiculos/buscarTiposDeVeiculos',
    CRIAR_IMPLEMENTO_VEICULO: '/veiculos/criarImplementoVeiculo',
    CADASTRAR_VEICULO: '/veiculos/cadastrarVeiculo',
  },
  STATUS: {
    VERIFICAR_STATUS: '/status/verificarStatus',
  },
  ENDOR: {
    LISTAR_VISTORIADORES: '/endor/listar-vistoriadores',
  },
  PLANOS: {
    BUSCAR_PLANOS_SEGMENTO: '/planos/buscarPlanos',
    BUSCAR_PLANOS_ADICIONAIS: '/planos/buscarAdicionais',
    BUSCAR_PLANOS_SEGMENTO_: '/planos/buscarPlanos',
  },
  VISTORIA: {
    CRIAR_VISTORIA: '/vistoria/criar-vistoria',
  },
  CLIENTES: {
    CADASTRAR_CLIENTES: '/clientes/cadastrar-cliente',
  },
  NABOO: {
    CIDADES: '/naboo/cidades',
  },
  PDF: {
    RENDER_COTACAO: '/pdf/renderCotacao',
  },
  EMPRESA: {
    VALIDAR_EMPRESA: '/empresa/validarEmpresa',
  },
  BACKGROUND: {
    BUSCAR_BACKGROUND: '/buscarBackground',
  },
  EMPRESA_ONBOARDING: {
    VALIDAR_EMPRESA: '/empresa/validarEmpresa',
    CADASTRAR_EMPRESA: '/empresa/cadastrarEmpresa',
  },
}
