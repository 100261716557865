import axios from 'axios'
import React, { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
// import { ButtonSalvarSenha } from "../../../Home/HomeRoutes/Perfil/styledPerfil";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ButtonEncerrar } from '../../../../Home/HomeRoutes/Oportunidades/Data Table/ResumoTable/styledResumoTable'
import {
  BackgroundModal,
  ModalBoxConfirmar,
  ModalHeader,
  X,
} from '../../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'

function ConfirmarArquivar({ openArchiveModal, handleArchive, cotId }) {
  // const [loading, setLoading] = useState(false);

  return (
    <BackgroundModal>
      <ToastContainer />
      {/* {loading && <Loading />} */}
      <ModalBoxConfirmar>
        <ModalHeader>
          <h2>Deseja arquivar?</h2>
          <X
            onClick={() => {
              openArchiveModal(false)
            }}
          >
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        <div className="modalBody">
          <p>
            Deseja arquivar este desconto? Ele não estará disponível para os
            vendedores selecionados.
          </p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '5px',
              gap: '0.5rem',
            }}
          >
            <button
              className="btnClose"
              onClick={() => {
                openArchiveModal(false)
              }}
            >
              Cancelar
            </button>
            <ButtonEncerrar
              style={{ marginBottom: '0' }}
              onClick={() => {
                // setLoading(true);
                handleArchive(cotId)
              }}
            >
              Arquivar
            </ButtonEncerrar>
          </div>
        </div>
      </ModalBoxConfirmar>
    </BackgroundModal>
  )
}

export default ConfirmarArquivar
