import React, { useState, useEffect } from 'react'
import { BtnSubmit } from '../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { FaPlus } from 'react-icons/fa'
import { MdUnarchive, MdEdit } from 'react-icons/md'
import NovaCota from './NovaCota/NovaCota'
import { TableButton } from '../../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import { ToastContainer, toast } from 'react-toastify'
import Loading from '../../../Loading/Loading'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import axios from 'axios'
import { COLORS } from '../../../../colors'
import ConfirmarDesarquivar from './ConfirmarDesarquivar/ConfirmarDesarquivar'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'

function CotaParticipacao() {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [openModal, setOpenModal] = useState(false)
  const [openArchiveModal, setOpenArchiveModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [dataList, setDataList] = useState([])
  const [cotId, setCotId] = useState('')

  useEffect(() => {
    ;(async () => {
      const buscarTabelaCotaParticipacao = async () => {
        try {
          const { dados } =
            await parametrosRepository.listarTabelaCotaParticipacao()
          setDataList(dados)
          setLoading(false)
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
      }

      buscarTabelaCotaParticipacao()

      /*
      axios
        .get(
          'https://kaminocrm.com.br:7778/parametros/listarTabelaDeCotaDeParticipacao',
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          },
        )
        .then((res) => {
          setDataList(res.data?.dados)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
      */
    })()
  }, [openModal, openArchiveModal])

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'warn') {
      toast.warn(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      toast.info('Internal server error', {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  async function handleArchive(cot_id) {
    try {
      const data =
        await parametrosRepository.arquivarOuDesarquivarCotaParticipacao(cot_id)

      console.log('ARQUIVAR OU DESARQUIVAR', data)
      setOpenModal(false)
      setOpenArchiveModal(false)
      feedback(data?.message, 'success')
    } catch (error) {
      console.log(error)
    }

    // await axios
    //   .post(
    //     `https://kaminocrm.com.br:7778/parametros/arquivarEDesarquivarCotaDeParticipacao/${cot_id}`,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${JWT_TOKEN}`,
    //         user_login: LOGIN,
    //         ass_cod: ASS_COD,
    //       },
    //     },
    //   )
    //   .then((res) => {
    //     setOpenModal(false)
    //     setOpenArchiveModal(false)
    //     feedback(res.data?.message, 'success')
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     // setOpenArchiveModal(false)
    //   })
  }

  function checarStatus(COT_ATIVO) {
    if (COT_ATIVO) {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.pendente}`,
            padding: '2px 10px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ATIVO
        </span>
      )
    } else {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.perdida}`,
            padding: '2px 7px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ARQUIVADO
        </span>
      )
    }
  }

  return (
    <>
      {loading && <Loading />}
      {openArchiveModal && (
        <ConfirmarDesarquivar
          openArchiveModal={setOpenArchiveModal}
          handleArchive={handleArchive}
          cotId={cotId}
          setCotId={setCotId}
        />
      )}
      {openModal && (
        <NovaCota
          openModal={setOpenModal}
          feedback={feedback}
          cotId={cotId}
          setCotId={setCotId}
          loading={loading}
          setLoading={setLoading}
          handleArchive={handleArchive}
        />
      )}
      {/* <ToastContainer /> */}
      <div className="criar">
        <BtnSubmit
          onClick={() => {
            setCotId('')
            setOpenModal(true)
          }}
        >
          <FaPlus size={11} /> <span>Nova cota de participação</span>
        </BtnSubmit>
      </div>
      <h3>Gerenciar cotas de participação</h3>

      <div className="table_container">
        <table className="table">
          <thead>
            <tr>
              <th>Descrição</th>
              <th>% (Min. / Máx )</th>
              <th>Valor Mínimo</th>
              <th>Tipo de Veículo</th>
              <th>Status</th>
              <th className="col_config">Ações</th>
            </tr>
          </thead>
          <tbody>
            {dataList?.map((el) => {
              const valorMinimo = Number(
                el.COT_VALOR_MINIMO.replace('.', '').replace(',', '.'),
              )
              return (
                <tr key={el.COT_ID}>
                  <td style={{ width: '170px' }}>
                    {el.COT_NOME.toUpperCase()}
                  </td>
                  <td>
                    {el.COT_PORCENTAGEM_MINIMA}% / {el.COT_PORCENTAGEM_MAXIMA}%
                  </td>
                  <td>
                    {valorMinimo.toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td>{el.COT_TIPO_VEICULO}</td>
                  <td>{checarStatus(el.COT_ATIVO)}</td>
                  <td className="col_config">
                    {el.COT_ATIVO ? (
                      <Tippy
                        content="Editar cota"
                        delay="275"
                        placement="bottom"
                      >
                        <TableButton
                          onClick={() => {
                            setLoading(true)
                            setCotId(el.COT_ID)
                            setOpenModal(true)
                          }}
                        >
                          <MdEdit size={20} />
                        </TableButton>
                      </Tippy>
                    ) : (
                      <Tippy
                        content="Desarquivar cota"
                        delay="275"
                        placement="bottom"
                      >
                        <TableButton
                          onClick={() => {
                            setCotId(el.COT_ID)
                            setOpenArchiveModal(true)
                          }}
                        >
                          <MdUnarchive size={22} />
                        </TableButton>
                      </Tippy>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default CotaParticipacao
