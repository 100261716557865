import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COLORS } from '../../../../../colors'

export const AddButton = styled(Link)`
  padding: 12px 15px 0px 15px;
  height: 30px;
  background-color: ${COLORS.primary};
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 110px;
  text-align: center;
`

export const Container = styled.div`
  padding: 0px 40px;
`

export const SwitchBotoes = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  gap: 0.3rem;
  margin-top: 2rem;

  @media only screen and (max-width: 600px) {
    margin: 0 0 10px 0;
  }

  h2 {
    margin: 0 0.4rem 0 0;
    font-size: 1.6em;
  }

  .funis {
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    :focus {
      outline: none;
      border: 1px solid lightblue;
    }
  }

  button {
    border: none;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.4rem;
    height: 1.3rem;
    background-color: #eee;
    color: ${COLORS.labelDark};
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    :hover {
      background-color: ${COLORS.lightGray};
    }
  }

  .active {
    background-color: #2c5a9f;
    color: white;

    :hover {
      background-color: #1e3f8d;
    }
  }
`

export const FunisBotoes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;

  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    margin: 0.7rem 20px 1.3rem 20px;
    padding: 6px;
    border-radius: 4px;
    background-color: #eee;
    width: fit-content;

    @media only screen and (max-width: 600px) {
      margin: 0 0 10px 0;
    }

    span {
      background-color: #fff;
      padding: 6px;
      box-shadow: 0 2px 10px #d9d9d980;
      border-radius: 4px;
      font-size: 13px;
      font-weight: 500;

      :hover {
        cursor: pointer;
      }
    }

    .btn_filter {
      padding: 0;
      color: ${COLORS.primary};
      background-color: transparent;
      border: 0;
      :hover {
        color: ${COLORS.primaryHover};
        background-color: transparent;
      }
    }
  }
`
