import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { FunilWrapper } from './styledFunis'
import { ButtonSalvarSenha } from '../../Home/HomeRoutes/Perfil/styledPerfil'
import Loading from '../../Loading/Loading'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { COLORS } from '../../../colors'
import { MdUnarchive, MdEdit, MdSettings } from 'react-icons/md'
import { TableButton } from '../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import ModalAlterar from './ModalAlterar/ModalAlterar'
import ConfirmarDesarquivar from './ConfirmarDesarquivar/ConfirmarDesarquivar'
import Novo from './Novo/Novo'
import Configuracoes from './Configuracoes/Configuracoes'
import { FaPlus } from 'react-icons/fa'
import { ImInfo } from 'react-icons/im'
import { BtnSubmit } from '../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { parametrosRepository } from '../../../api/repositories/parametrosRepository'

function Funis() {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [loading, setLoading] = useState(false)

  const [nomeFunil, setNomeFunil] = useState('')
  const [funis, setFunis] = useState([])
  const [openModalAlterar, setOpenModalAlterar] = useState(false)
  const [openModalDesarquivar, setOpenModalDesarquivar] = useState(false)
  const [openModalConfig, setOpenModalConfig] = useState(false)
  const [idFunil, setIdFunil] = useState('')
  const [vendedoresFunil, setVendedoresFunil] = useState([])
  const [modalNovo, setModalNovo] = useState(false)

  useEffect(() => {
    const buscarFunis = async () => {
      try {
        const { funis } = await parametrosRepository.listarFunis()
        setFunis(funis)
      } catch (error) {
        console.log(error)
      }
    }

    buscarFunis()

    /*
    axios
      .get('https://kaminocrm.com.br:7778/parametros/listarFunis/', {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setFunis(res.data.funis)
      })
      .catch((err) => {
        console.log(err)
      })
    */
  }, [])

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  function checarStatus(FUN_ATIVO) {
    if (FUN_ATIVO) {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.pendente}`,
            padding: '2px 10px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ATIVO
        </span>
      )
    } else {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.perdida}`,
            padding: '2px 7px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ARQUIVADO
        </span>
      )
    }
  }
  return (
    <>
      {modalNovo && <Novo openModal={setModalNovo} />}
      {/* <ToastContainer /> */}
      <FunilWrapper>
        {openModalAlterar && (
          <ModalAlterar
            openModal={setOpenModalAlterar}
            FUN_ID={idFunil}
            VEN_IDS={vendedoresFunil}
            nomeFunil={nomeFunil}
          />
        )}
        {openModalDesarquivar && (
          <ConfirmarDesarquivar
            openModal={setOpenModalDesarquivar}
            FUN_ID={idFunil}
          />
        )}
        {openModalConfig && (
          <Configuracoes openModal={setOpenModalConfig} FUN_ID={idFunil} />
        )}

        {loading && <Loading />}

        <div className="criar">
          <BtnSubmit onClick={() => setModalNovo(true)}>
            <FaPlus size={11} /> <span>Adicionar funil</span>
          </BtnSubmit>
        </div>

        <h3>Gerenciar funis</h3>
        <div className="funis_existem">
          <table>
            <thead>
              <tr>
                <th className="col_nome">Nome</th>
                <th className="col_status">Status</th>
                <th className="col_acoes">Ações</th>
                {/* <th className="col_config">Configurações</th> */}
              </tr>
            </thead>
            <tbody>
              {funis.map((i) => {
                return (
                  <tr key={i.FUN_ID}>
                    <td className="col_nome">{i.FUN_NOME}</td>
                    <td className="col_status">{checarStatus(i.FUN_ATIVO)}</td>
                    <td className="col_acoes">
                      {i.FUN_PADRAO ? (
                        ''
                      ) : i.FUN_ATIVO ? (
                        <Tippy
                          content="Editar funil"
                          delay="275"
                          placement="bottom"
                        >
                          <TableButton
                            onClick={() => {
                              setOpenModalAlterar(true)
                              setIdFunil(i.FUN_ID)
                              setVendedoresFunil(i.VEN_IDS)
                              setNomeFunil(i.FUN_NOME)
                              window.scrollTo(0, 0)
                            }}
                          >
                            <MdEdit size={20} />
                          </TableButton>
                        </Tippy>
                      ) : (
                        <Tippy
                          content="Desarquivar funil"
                          delay="275"
                          placement="bottom"
                        >
                          <TableButton
                            onClick={() => {
                              setOpenModalDesarquivar(true)
                              setIdFunil(i.FUN_ID)
                              window.scrollTo(0, 0)
                            }}
                          >
                            <MdUnarchive size={22} />
                          </TableButton>
                        </Tippy>
                      )}

                      {i.FUN_PADRAO ? (
                        <Tippy
                          content="O funil padrão não pode ser alterado"
                          delay="275"
                          placement="bottom"
                        >
                          <span>
                            <ImInfo size={19} />
                          </span>
                        </Tippy>
                      ) : (
                        <Tippy
                          content="Gerenciar etapas"
                          delay="275"
                          placement="bottom"
                        >
                          <TableButton
                            onClick={() => {
                              setOpenModalConfig(true)
                              setIdFunil(i.FUN_ID)
                              window.scrollTo(0, 0)
                            }}
                          >
                            <MdSettings size={22} />
                          </TableButton>
                        </Tippy>
                      )}
                    </td>
                    {/* <td className="col_config">
                      {i.FUN_PADRAO ? <Tippy
                          content="O funil padrão não pode ser alterado"
                          delay="275"
                          placement="bottom"
                        >
                          <span>
                            <ImInfo size={19} />
                          </span>
                        </Tippy> : 
                        <Tippy
                        content="Gerenciar etapas"
                        delay="275"
                        placement="bottom"
                      >
                        <TableButton
                        onClick={() => {
                          setOpenModalConfig(true);
                          setIdFunil(i.FUN_ID);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <MdSettings size={22} />
                      </TableButton>
                      </Tippy>}
                      
                    </td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </FunilWrapper>
    </>
  )
}

export default Funis
