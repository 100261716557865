import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  BackgroundModal,
  BtnSubmit,
  ModalBoxCliente,
  BtnRecusar,
  ModalHeader,
  X,
} from '../../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { IoMdClose } from 'react-icons/io'
import Select from 'react-select'
import { MultiSelect } from 'react-multi-select-component'
import ConfirmarArquivar from '../ConfirmarArquivar/ConfirmarArquivar'
import { NumericFormat } from 'react-number-format'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import { veiculoRepository } from '../../../../../api/repositories/veiculosRepository'

function NovaCota({
  openModal,
  feedback,
  cotId,
  setCotId,
  setLoading,
  handleArchive,
}) {
  const DEFAULT_VALUES = {
    COT_NOME: '',
    COT_VALOR_MINIMO: 0,
    COT_PORCENTAGEM_MINIMA: 0,
    COT_PORCENTAGEM_MAXIMA: 0,
    COT_VEN_IDS: [],
    COT_TIPO_VEICULO: {},
  }

  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [openArchiveModal, setOpenArchiveModal] = useState(false)
  const [cotaDados, setCotaDados] = useState(DEFAULT_VALUES)
  const [tiposVeiculo, setTiposVeiculo] = useState([])
  const [vendedores, setVendedores] = useState([])
  const [porcetagemMinimaError, setPorcetagemMinimaError] = useState(false)

  async function requestsAPI() {
    const buscarTiposVeiculos = async () => {
      try {
        const { tipos } = await veiculoRepository.buscarTiposVeiculos()
        setTiposVeiculo(tipos)
      } catch (error) {
        console.error(error)
      }
    }

    buscarTiposVeiculos()

    /*
    await axios
      .get('https://kaminocrm.com.br:7778/veiculos/buscarTiposDeVeiculos', {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setTiposVeiculo(res.data.tipos)
      })
      .catch((err) => {
        console.log(err)
      })
    */

    const buscarVendedores = async () => {
      try {
        const vendedores = await parametrosRepository.buscarVendedores()
        setVendedores(vendedores)
      } catch (error) {
        console.error(error)
      }
    }

    buscarVendedores()

    /*
    await axios
      .get('https://kaminocrm.com.br:7778/parametros/listarVendedores', {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setVendedores(res.data)
      })
      .catch((err) => console.error(err))

    */

    if (cotId !== '') {
      setLoading(true)
      const buscarTabelaCotaParticipacao = async () => {
        try {
          const data =
            await parametrosRepository.buscarTabelaCotaParticipacao(cotId)

          console.log('DADOS COTA PARTICIPACAO', data)

          setCotaDados({
            COT_NOME: data?.dados[0].COT_NOME,
            COT_VALOR_MINIMO: `R$${data?.dados[0].COT_VALOR_MINIMO}`,
            COT_PORCENTAGEM_MINIMA: Number(
              data?.dados[0].COT_PORCENTAGEM_MINIMA,
            ),
            COT_PORCENTAGEM_MAXIMA: Number(
              data?.dados[0].COT_PORCENTAGEM_MAXIMA,
            ),
            COT_VEN_IDS: data?.dados[0].COT_VEN_IDS,
            COT_TIPO_VEICULO: data?.dados[0].COT_TIPO_VEICULO,
          })

          setLoading(false)
        } catch (error) {
          console.log(error)
          setLoading(false)
          feedback('Ops, algo deu errado!', 'error')
          closeModal()
        }
      }

      buscarTabelaCotaParticipacao()

      /*
      await axios
        .get(
          `https://kaminocrm.com.br:7778/parametros/buscarTabelaDeCotaDeParticipacao?cot_id=${cotId}`,
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          },
        )
        .then((res) => {
          setCotaDados({
            COT_NOME: res.data?.dados[0].COT_NOME,
            COT_VALOR_MINIMO: `R$${res.data?.dados[0].COT_VALOR_MINIMO}`,
            COT_PORCENTAGEM_MINIMA: Number(
              res.data?.dados[0].COT_PORCENTAGEM_MINIMA,
            ),
            COT_PORCENTAGEM_MAXIMA: Number(
              res.data?.dados[0].COT_PORCENTAGEM_MAXIMA,
            ),
            COT_VEN_IDS: res.data?.dados[0].COT_VEN_IDS,
            COT_TIPO_VEICULO: res.data?.dados[0].COT_TIPO_VEICULO,
          })

          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          feedback('Ops, algo deu errado!', 'error')
          closeModal()
        })
      */
    }
  }

  useEffect(() => {
    requestsAPI()
  }, [])

  const isFormValid = () => {
    const {
      COT_NOME,
      COT_VALOR_MINIMO,
      COT_PORCENTAGEM_MINIMA,
      COT_PORCENTAGEM_MAXIMA,
      COT_VEN_IDS,
      COT_TIPO_VEICULO,
    } = cotaDados
    return (
      COT_NOME !== '' &&
      COT_VALOR_MINIMO !== '' &&
      COT_PORCENTAGEM_MINIMA !== 0 &&
      COT_PORCENTAGEM_MINIMA <= COT_PORCENTAGEM_MAXIMA &&
      COT_PORCENTAGEM_MAXIMA !== 0 &&
      Object.keys(COT_TIPO_VEICULO).length !== 0 &&
      COT_VEN_IDS.length !== 0
    )
  }

  function closeModal() {
    setCotId('')
    openModal(false)
    setCotaDados(DEFAULT_VALUES)
  }

  async function handleSubmit() {
    if (cotId === '') {
      try {
        const data =
          await parametrosRepository.criarTabelaCotaParticipacao(cotaDados)

        console.log('CRIAR TABELA COTA PARTICIPACAO', data)
        feedback('Cota criada com sucesso', 'success')
        closeModal()
      } catch (error) {
        console.log(error)
        feedback('Ops! Algo deu errado', 'error')
      }
      // await axios
      //   .post(
      //     'https://kaminocrm.com.br:7778/parametros/criarTabelaDeCotaDeParticipacao',
      //     cotaDados,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${JWT_TOKEN}`,
      //         user_login: LOGIN,
      //         ass_cod: ASS_COD,
      //       },
      //     },
      //   )
      //   .then((res) => {
      //     feedback('Cota criada com sucesso', 'success')
      //     closeModal()
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //     feedback('Ops! Algo deu errado', 'error')
      //   })
    } else {
      const dataEdit = { ...cotaDados, COT_ID: cotId }
      try {
        const data =
          await parametrosRepository.editarTabelaCotaParticipacao(dataEdit)

        console.log('EDITAR TABELA COTA PARTICIPACAO', data)
        feedback('Cota editada com sucesso', 'success')
        closeModal()
      } catch (error) {
        console.log(error)
        feedback('Ops! Algo deu errado', 'error')
      }
      // await axios
      //   .post(
      //     'https://kaminocrm.com.br:7778/parametros/editarTabelaDeCotaDeParticiapcao',
      //     dataEdit,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${JWT_TOKEN}`,
      //         user_login: LOGIN,
      //         ass_cod: ASS_COD,
      //       },
      //     },
      //   )
      //   .then((res) => {
      //     feedback('Cota editada com sucesso', 'success')
      //     closeModal()
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //     feedback('Ops! Algo deu errado', 'error')
      //   })
    }
  }

  return (
    <BackgroundModal>
      {openArchiveModal && (
        <ConfirmarArquivar
          openArchiveModal={setOpenArchiveModal}
          handleArchive={handleArchive}
          cotId={cotId}
          setLoading={setLoading}
        />
      )}
      <ModalBoxCliente>
        <ModalHeader>
          <h2>Criar nova cota de participação</h2>
          <X onClick={() => closeModal()}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        <div className="criar--body">
          <div className="criar--inputs">
            <div className="input_container">
              <label htmlFor="descricao_filiacao">Descrição</label>
              <input
                type="text"
                className="input_criar"
                name="descricao_filiacao"
                id="descricao_filiacao"
                value={cotaDados.COT_NOME}
                onChange={(e) =>
                  setCotaDados((prev) => ({
                    ...prev,
                    COT_NOME: e.target.value,
                  }))
                }
              />
            </div>
            <div className="input_container">
              <label htmlFor="descricao_filiacao">Valor mínimo</label>
              <NumericFormat
                value={cotaDados.COT_VALOR_MINIMO}
                prefix="R$"
                thousandSeparator="."
                decimalSeparator=","
                className="input_criar"
                allowLeadingZeros={false}
                allowNegative={false}
                decimalScale={2}
                onChange={(e) => {
                  setCotaDados((prev) => ({
                    ...prev,
                    COT_VALOR_MINIMO: e.target.value,
                  }))
                }}
              />
            </div>
          </div>
          <div className="criar--inputs">
            <div className="input_container">
              <label htmlFor="descricao_filiacao">Porcentagem mínima</label>
              <input
                type="text"
                pattern="\d*"
                className={
                  porcetagemMinimaError ? 'input_criar_error' : 'input_criar'
                }
                value={cotaDados.COT_PORCENTAGEM_MINIMA}
                onChange={(e) => {
                  const { value } = e.target
                  const pattern = /^\d*$/
                  const maxLength = 2

                  if (pattern.test(value) && value.length <= maxLength) {
                    setCotaDados((prev) => ({
                      ...prev,
                      COT_PORCENTAGEM_MINIMA: Number(e.target.value),
                    }))
                    setPorcetagemMinimaError(false)
                  }
                  if (value > cotaDados.COT_PORCENTAGEM_MAXIMA) {
                    setPorcetagemMinimaError(true)
                  }
                }}
              />
              {porcetagemMinimaError && (
                <span className="spanError">
                  A Porcetagem mínima não pode exceder a máxima.
                </span>
              )}
            </div>
            <div className="input_container">
              <label htmlFor="porcentagem_filiacao">Porcentagem máxima</label>
              <input
                type="text"
                pattern="\d*"
                className="input_criar"
                value={cotaDados.COT_PORCENTAGEM_MAXIMA}
                onChange={(e) => {
                  const { value } = e.target
                  const pattern = /^\d*$/
                  const maxLength = 2

                  if (pattern.test(value) && value.length <= maxLength) {
                    setCotaDados((prev) => ({
                      ...prev,
                      COT_PORCENTAGEM_MAXIMA: Number(e.target.value),
                    }))
                  } else {
                    return
                  }
                  if (value >= cotaDados.COT_PORCENTAGEM_MINIMA) {
                    setPorcetagemMinimaError(false)
                  }
                  if (value < cotaDados.COT_PORCENTAGEM_MINIMA) {
                    setPorcetagemMinimaError(true)
                  }
                }}
              />
            </div>
          </div>
          <div className="criar--inputs">
            <div className="input_container">
              <label htmlFor="">Por vendedores</label>
              <MultiSelect
                options={vendedores}
                value={cotaDados.COT_VEN_IDS}
                onChange={(e) => {
                  setCotaDados((prev) => ({ ...prev, COT_VEN_IDS: e }))
                }}
                labelledBy="Selecione..."
                className="multiselect_criar"
                overrideStrings={{
                  allItemsAreSelected: 'Todos os itens selecionados.',
                  clearSearch: 'Limpar pesquisa...',
                  clearSelected: 'Limpar selecionados...',
                  noOptions: 'Sem opções disponíveis...',
                  search: 'Pesquise...',
                  selectAll: 'Selecionar todos',
                  selectAllFiltered: 'Selecionar todos (PESQUISA)',
                  selectSomeItems: 'Selecione...',
                  create: 'Create',
                }}
              />
            </div>
            <div className="input_container">
              <label htmlFor="">Por tipo de veículo</label>
              <Select
                options={tiposVeiculo}
                className="select_criar"
                value={cotaDados.COT_TIPO_VEICULO}
                placeholder="Selecione..."
                onChange={(e) =>
                  setCotaDados((prev) => ({
                    ...prev,
                    COT_TIPO_VEICULO: e,
                  }))
                }
              />
            </div>
          </div>
        </div>

        <div className="criar--footer">
          {cotId !== '' && (
            <BtnRecusar onClick={() => setOpenArchiveModal(true)}>
              Arquivar filiação
            </BtnRecusar>
          )}
          <BtnSubmit onClick={() => handleSubmit()} disabled={!isFormValid()}>
            Salvar
          </BtnSubmit>
        </div>
      </ModalBoxCliente>
    </BackgroundModal>
  )
}

export default NovaCota
