import { apiUser } from '../api'
import { ENDPOINTS } from '../endPoints'

export const userRepository = {
  buscarUsuarios: async () => {
    try {
      const response = await apiUser.get(ENDPOINTS.USUARIOS.BUSCAR)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  updateUsuario: async (DADOS) => {
    try {
      const response = await apiUser.post(ENDPOINTS.USUARIOS.UPDATE, DADOS)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  trocarSenha: async () => {
    try {
      const response = await apiUser.post(ENDPOINTS.USUARIOS.TROCAR_SENHA)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  cadastrarUsuario: async (DADOS) => {
    try {
      const response = await apiUser.post(ENDPOINTS.USUARIOS.CADASTRAR, DADOS)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  updateUsuarioV2: async (DADOS) => {
    try {
      const response = await apiUser.post(
        ENDPOINTS.USUARIOS.UPDATE_USUARIO_V2,
        DADOS,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  arquivarUsuario: async (USR_ID, USR_ATIVO) => {
    try {
      const response = await apiUser.post(ENDPOINTS.USUARIOS.ARQUIVAR_USUARIO, {
        USR_ID,
        ARQUIVAR: USR_ATIVO,
      })

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  buscarUsuarioId: async (USR_ID) => {
    try {
      const response = await apiUser.get(
        `${ENDPOINTS.USUARIOS.BUSCAR_ID}/${USR_ID}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
