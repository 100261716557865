import { apiEmpresa, apiEmpresaOnboarding } from '../api'
import { ENDPOINTS } from '../endPoints'

export const empresaRepository = {
  validarEmpresa: async (empresaCodigo) => {
    try {
      const response = await apiEmpresa.get(
        `${ENDPOINTS.EMPRESA.VALIDAR_EMPRESA}?EMP_COD=${empresaCodigo}`,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}

export const empresaOnboardingRepository = {
  validarEmpresa: async (code) => {
    try {
      const response = await apiEmpresaOnboarding.get(
        `${ENDPOINTS.EMPRESA_ONBOARDING.VALIDAR_EMPRESA}?ass_cod=${code}`,
      )

      return response.data
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response
        const message = data.message

        console.log(`Erro: ${message}, Status: ${status}`)

        throw { status, message }
      } else {
        console.error('Erro inesperado:', error)
        throw error
      }
    }
  },

  cadastrarEmpresa: async (data) => {
    try {
      const response = await apiEmpresaOnboarding.post(
        ENDPOINTS.EMPRESA_ONBOARDING.CADASTRAR_EMPRESA,
        data,
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}
