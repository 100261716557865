import React, { useState, useEffect } from 'react'
import { IoMdClose } from 'react-icons/io'
import {
  BackgroundModal,
  ModalBoxCliente,
  ModalHeader,
  X,
} from '../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../../Loading/Loading'
import axios from 'axios'
import { ButtonEncerrar } from '../../../Home/HomeRoutes/Oportunidades/Data Table/ResumoTable/styledResumoTable'
import { ButtonSalvarSenha } from '../../../Home/HomeRoutes/Perfil/styledPerfil'
import { FunilWrapper } from '../styledFunis'
import { MultiSelect } from 'react-multi-select-component'
import ConfirmarArquivar from '../ConfirmarArquivar/ConfirmarArquivar'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'

function ModalAlterar({ openModal, FUN_ID, VEN_IDS, nomeFunil }) {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [loading, setLoading] = useState(false)
  const [novoNomeFunil, setNovoNomeFunil] = useState(nomeFunil)
  const [vendedores, setVendedores] = useState([])
  const [vendedoresEscolhidos, setVendedoresEscolhidos] = useState(VEN_IDS)
  const [modalArquivar, setModalArquivar] = useState(false)

  useEffect(() => {
    const buscarVendedores = async () => {
      try {
        const vendedores = await parametrosRepository.buscarVendedores()
        setVendedores(vendedores)
      } catch (error) {
        console.error(error)
      }
    }

    buscarVendedores()

    /*
    axios
      .get('https://kaminocrm.com.br:7778/parametros/listarVendedores', {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        console.log(res.data)
        setVendedores(res.data)
      })
      .catch((err) => console.error(err))
    */
  }, [])

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  async function editarNomeFunil() {
    try {
      const data = await parametrosRepository.atualizarFunil(
        novoNomeFunil,
        FUN_ID,
        vendedoresEscolhidos,
      )

      console.log('EDITAR NOME FUNIL', data)
      feedback('Funil criado com sucesso!', 'success')
      setTimeout(function () {
        setLoading(false)
      }, 1800)
      if (!loading) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
      feedback('Ops! Algo deu errado.', 'error')
      setLoading(false)
    }

    /*
    axios
      .post(
        'https://kaminocrm.com.br:7778/parametros/atualizarFunil',
        {
          FUN_NOME: novoNomeFunil,
          FUN_ID: FUN_ID,
          VEN_IDS: vendedoresEscolhidos,
        },
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        },
      )
      .then((res) => {
        console.log(res)
        feedback('Funil criado com sucesso!', 'success')
        setTimeout(function () {
          setLoading(false)
        }, 1800)
        if (!loading) {
          window.location.reload()
        }
      })
      .catch((err) => {
        console.log(err)
        feedback('Ops! Algo deu errado.', 'error')
        setLoading(false)
      })
    */
  }

  return (
    <BackgroundModal>
      <ToastContainer />
      {loading && <Loading />}
      {modalArquivar && (
        <ConfirmarArquivar openModal={setModalArquivar} FUN_ID={FUN_ID} />
      )}
      <ModalBoxCliente>
        <ModalHeader>
          <h2>Editar Funil</h2>
          <X onClick={() => openModal(false)}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>
        <div className="modalBody">
          <FunilWrapper>
            <div className="wrapper">
              <div className="novo_funil_duo">
                <label htmlFor="novo_nome_funil">Renomear Funil</label>
                <input
                  className="input"
                  type="text"
                  name="novo_nome_funil"
                  id="novo_nome_funil"
                  value={novoNomeFunil}
                  onChange={(e) => {
                    setNovoNomeFunil(e.target.value)
                  }}
                />
                <div className="novo_funil-footer" />
              </div>

              <div className="novo_funil_duo">
                <label style={{ marginTop: '0.25rem' }} htmlFor="nome_funil">
                  Editar usuários com acesso
                </label>
                <MultiSelect
                  options={vendedores}
                  value={vendedoresEscolhidos}
                  onChange={setVendedoresEscolhidos}
                  labelledBy="Selecione..."
                  className="rmsc"
                  overrideStrings={{
                    allItemsAreSelected: 'Todos os itens selecionados.',
                    clearSearch: 'Limpar pesquisa...',
                    clearSelected: 'Limpar selecionados...',
                    noOptions: 'Sem opções disponíveis...',
                    search: 'Pesquise...',
                    selectAll: 'Selecionar todos',
                    selectAllFiltered: 'Selecionar todos (PESQUISA)',
                    selectSomeItems: 'Selecione...',
                    create: 'Create',
                  }}
                />
                <div className="novo_funil-footer">
                  <ButtonEncerrar
                    onClick={() => {
                      // setLoading(true);
                      setModalArquivar(true)
                    }}
                    style={{ marginTop: '15px' }}
                  >
                    Arquivar funil
                  </ButtonEncerrar>
                  <ButtonSalvarSenha
                    onClick={() => {
                      setLoading(true)
                      editarNomeFunil()
                    }}
                    style={{ marginTop: '15px' }}
                  >
                    Salvar alterações
                  </ButtonSalvarSenha>
                </div>
              </div>
            </div>
          </FunilWrapper>
        </div>
      </ModalBoxCliente>
    </BackgroundModal>
  )
}

export default ModalAlterar
