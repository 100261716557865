import React, { useState, useEffect } from 'react'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { FaPlus } from 'react-icons/fa'
import Select from 'react-select'
import ActionItem from './ActionItem/ActionItem'
import { values } from 'lodash'

function TriggerItem({
  item,
  index,
  columnsFunil,
  optionsStatus,
  handleInfoTrigger,
  removeTrigger,
}) {
  function handleEvents(tgr) {
    if (tgr === 'STATUS') {
      return 'ALTER'
    } else if (tgr === 'OPORTUNIDADE') {
      return 'CREATE'
    } else if (tgr === 'ETAPA') {
      return 'MOVE'
    } else {
      return item.AUT_EVENT ? item.AUT_EVENT : ''
    }
  }

  const [trigger, setTrigger] = useState({
    AUT_TRIGGER: item.AUT_TRIGGER, // STATUS, ETAPA, TAREFA, OPORTUNIDADE
    AUT_EVENT: handleEvents(item.AUT_TRIGGER), // CREATE, MOVE, ALTER, DELETE
    AUT_FROM: item.AUT_FROM ? item.AUT_FROM : '', // TAREFA && null
    AUT_TO: item.AUT_TO ? item.AUT_TO : '', // TAREFA || (STATUS && AUT_EVENT === 'CREATE') && null
    AUT_ACTIONS: item.AUT_ACTIONS ? item.AUT_ACTIONS : '',
  })

  const optionsTypes = {
    TAREFA: [
      {
        type: 'Criar tarefa',
        id: 'CREATE',
      },
      {
        type: 'Concluir tarefa',
        id: 'ALTER',
      },
    ],
  }

  function handleChange(name, value) {
    setTrigger((prev) => ({
      ...prev,
      [name]: value,
    }))

    const copyTrigger = {
      ...trigger,
      [name]: value,
    }

    handleInfoTrigger(index, copyTrigger)
  }

  function createAction() {
    const newTrigger = [
      ...trigger.AUT_ACTIONS,
      {
        ACTION_TIPO: '',
        MENSAGEM_ID: '',
        COLUNA_NOME: '',
        STATUS_NOME: '',
      },
    ]
    handleChange('AUT_ACTIONS', newTrigger)
  }

  function handleInfoAction(index, newInfo) {
    const newInfoList = [...trigger.AUT_ACTIONS]
    newInfoList[index] = newInfo
    handleChange('AUT_ACTIONS', newInfoList)
  }

  function removeAction(item) {
    const updatedInfo = trigger.AUT_ACTIONS.filter((i) => i !== item)
    setTrigger((prev) => ({
      ...prev,
      AUT_ACTIONS: updatedInfo,
    }))
  }

  return (
    <div className="trigger_item">
      <button className="delete_trigger" onClick={() => removeTrigger(item)}>
        <RiDeleteBin5Fill size={18} />
      </button>
      <span className="trigger_title">Automação {index + 1}</span>
      {trigger.AUT_TRIGGER === 'TAREFA' && (
        <>
          <span>Quando:</span>
          <div className="select_type_trigger">
            {optionsTypes[item.AUT_TRIGGER]?.map(({ id, type }) => {
              return (
                <div
                  key={id}
                  className={`type_option ${
                    trigger.AUT_EVENT === id ? 'selected_item' : ''
                  }`}
                  onClick={() => handleChange('AUT_EVENT', id)}
                >
                  {type}
                </div>
              )
            })}
          </div>
        </>
      )}

      {trigger.AUT_TRIGGER === 'OPORTUNIDADE' && (
        <label>Quando criar oportunidade:</label>
      )}

      {trigger.AUT_TRIGGER !== 'TAREFA' &&
      trigger.AUT_TRIGGER !== 'OPORTUNIDADE' ? (
        <div className="select_path_trigger">
          <div className="path_from">
            <span>De:</span>
            <Select
              options={
                trigger.AUT_TRIGGER === 'STATUS' ? optionsStatus : columnsFunil
              }
              onChange={(e) => handleChange('AUT_FROM', e.value)}
              value={
                trigger?.AUT_TRIGGER === 'STATUS'
                  ? optionsStatus.find(
                      (el) =>
                        el.label.toUpperCase() ===
                        trigger?.AUT_FROM?.toUpperCase(),
                    )
                  : columnsFunil.find(
                      (el) =>
                        el.label.toUpperCase() ===
                        trigger?.AUT_FROM?.toUpperCase(),
                    )
              }
              placeholder="Selecione..."
            />
          </div>
          {(trigger.AUT_TRIGGER === 'STATUS' &&
            trigger.AUT_EVENT !== 'CREATE') ||
          trigger.AUT_TRIGGER === 'ETAPA' ? (
            <div className="path_to">
              <span>Para:</span>
              <Select
                options={
                  trigger.AUT_TRIGGER === 'STATUS'
                    ? optionsStatus
                    : columnsFunil
                }
                onChange={(e) => handleChange('AUT_TO', e.value)}
                value={
                  trigger?.AUT_TRIGGER === 'STATUS'
                    ? optionsStatus.find(
                        (el) =>
                          el.value.toUpperCase() ===
                          trigger?.AUT_TO?.toUpperCase(),
                      )
                    : columnsFunil.find(
                        (el) =>
                          el.value.toUpperCase() ===
                          trigger?.AUT_TO?.toUpperCase(),
                      )
                }
                placeholder="Selecione..."
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      {trigger.AUT_EVENT !== '' && (
        <div className="actions_container">
          <div className="add_action" onClick={() => createAction()}>
            <span className="icon">
              <FaPlus />
            </span>
            <span>Adicionar ação</span>
          </div>

          <div className="actions_list">
            {trigger.AUT_ACTIONS.length > 0 &&
              trigger.AUT_ACTIONS.map((el, index) => {
                return (
                  <ActionItem
                    item={el}
                    index={index}
                    trigger={trigger.AUT_TRIGGER}
                    handleInfoAction={handleInfoAction}
                    removeAction={removeAction}
                    columnsFunil={columnsFunil}
                    optionsStatus={optionsStatus}
                  />
                )
              })}
          </div>
        </div>
      )}
    </div>
  )
}

export default TriggerItem
