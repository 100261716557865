import axios from 'axios'
import React, { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import {
  BackgroundModal,
  ModalBoxConfirmar,
  ModalHeader,
  X,
} from '../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { ButtonSalvarSenha } from '../../../Home/HomeRoutes/Perfil/styledPerfil'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../../Loading/Loading'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'

function ConfirmarDesarquivar({ openModal, FUN_ID }) {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')
  const [loading, setLoading] = useState(false)

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  async function desarquivarFunil() {
    try {
      const data = await parametrosRepository.arquivarOuDesarquivarFunil(FUN_ID)

      console.log('ARQUIVANDO FUNIL', data)
      feedback('Funil arquivado com sucesso', 'success')
      setTimeout(function () {
        setLoading(false)
      }, 1800)
      if (!loading) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
      feedback('Ops! Algo deu errado.', 'error')
      setLoading(false)
    }

    /*
    axios
      .post(
        'https://kaminocrm.com.br:7778/parametros/arquivarOuDesarquivarFunil',
        { FUN_ID: FUN_ID },
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        },
      )
      .then((res) => {
        console.log(res)
        feedback('Funil desarquivado com sucesso', 'success')
        setTimeout(function () {
          setLoading(false)
        }, 1800)
        if (!loading) {
          window.location.reload()
        }
      })
      .catch((err) => {
        console.log(err)
        feedback('Ops! Algo deu errado.', 'error')
        setLoading(false)
      })
    */
  }

  return (
    <BackgroundModal>
      <ToastContainer />
      {loading && <Loading />}
      <ModalBoxConfirmar>
        <ModalHeader>
          <h2>Deseja desarquivar?</h2>
          <X
            onClick={() => {
              openModal(false)
            }}
          >
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        <div className="modalBody">
          <p>
            Deseja desarquivar este funil? As oportunidades atribuídas a ele
            poderão voltar a ser visualizadas.
          </p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '5px',
              gap: '0.5rem',
            }}
          >
            <button
              className="btnClose"
              // style={{
              //   padding: "0.5rem 0.75rem",
              //   fontSize: "14px",
              //   marginBottom: "0",
              // }}
              onClick={() => {
                openModal(false)
              }}
            >
              Cancelar
            </button>
            <ButtonSalvarSenha
              style={{ marginBottom: '0' }}
              onClick={() => {
                setLoading(true)
                desarquivarFunil()
              }}
            >
              Desarquivar
            </ButtonSalvarSenha>
          </div>
        </div>
      </ModalBoxConfirmar>
    </BackgroundModal>
  )
}

export default ConfirmarDesarquivar
