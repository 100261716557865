import React from 'react'
import AccordionTemplate from '../../../components/AccordionTemplate/AccordionTemplate'

function TagsList({ tagList }) {
  return (
    <div className="tag_list">
      <span className="tags_title">TAGS</span>
      <span className="tags_subtitle">
        Utilize as tags abaixo para criar/personalizar seu documento!
      </span>
      <span className="tags_subtitle">
        EX.: Para imprimir o Número do Contrato em um documento, utilize:
        [CONTRATO_NUMERO]
      </span>
      <div className="tags_list_container">
        {tagList.map((el) => {
          return (
            <AccordionTemplate title={el.tipoTag} subtitle="">
              <div className="items_accordion">
                {el.listaTags.map((i) => {
                  return (
                    <div className="tag_item">
                      <span className="tag_item_title">{i.tituloTag}</span>
                      <span className="tag_item_subtitle">
                        {i.descricaoTag}
                      </span>
                    </div>
                  )
                })}
              </div>
            </AccordionTemplate>
          )
        })}
      </div>
    </div>
  )
}

export default TagsList
