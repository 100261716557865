import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { CadastroVeiculo } from '../../../../../context/CadastroVEIContext'
import { IoMdClose } from 'react-icons/io'
import { FiAlertTriangle } from 'react-icons/fi'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  BackgroundModal,
  ModalBoxPlacaFIPE,
  ModalHeader,
  CloseLink,
} from './style/styledModal'
import { useNavigate } from 'react-router-dom'
import './style/ModalStyle.css'
import Loading from '../../../../Loading/Loading'
import Manual from './PlacaInputs/Manual/Manual.jsx'
import Fipe from './PlacaInputs/Fipe/Fipe.jsx'
import { handleClose } from '../../../../../services/handleClose'
import { fipeRepository } from '../../../../../api/repositories/fipeRepository.js'
import { planosRepository } from '../../../../../api/repositories/planosRepository.js'
import { veiculoRepository } from '../../../../../api/repositories/veiculosRepository.js'

const ModalPlacaFIPE = () => {
  const {
    setVEI_MARCA,
    setVEI_MODELO,
    setVEI_ANO,
    zeroKM,
    noFipe,
    setImplementos,
  } = useContext(CadastroVeiculo)

  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')
  const VEN_ID = sessionStorage.getItem('VEN_ID')
  const CLI_ID = sessionStorage.getItem('CLI_ID')
  const PLACA = sessionStorage.getItem('PLACA')

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const MySwal = withReactContent(Swal)

  const segmentos = [
    {
      label: 'CARRO',
      value: 1,
    },
    {
      label: 'MOTO',
      value: 2,
    },
    {
      label: 'CAMINHÃO',
      value: 3,
    },
  ]

  const tabelaDeReferenciaHack = () => {
    let today = new Date()
    let hoje = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()}`
    let data1 = new Date(1998, 8, 1)
    let data2 = new Date(hoje)
    var total =
      (data2.getFullYear() - data1.getFullYear()) * 12 +
      (data2.getMonth() - data1.getMonth())
    return total + 1
  }

  //ARRAYS
  const [referenciaFipe, setReferenciaFipe] = useState()
  const [marcasVei, setMarcasVei] = useState([])
  const [modelosVei, setModelosVei] = useState([])
  const [anoModeloVei, setAnoModeloVei] = useState([])

  //DATA
  const DEFAULT_DATA = {
    VEI_PLACA: '',
    VEI_MARCA: '',
    VEI_CODIGO_MARCA: '',
    VEI_MODELO: '',
    VEI_ANO_MODELO: '',
    VEI_CODIGO_ANO_MODELO: '',
    VEI_CODIGO_MODELO: '',
    VEI_ANO_FAB: '',
    VEI_COD_TABELA_FIPE: '',
    VEI_COD_FIPE: '',
    VEI_VALOR_FIPE: '',
    VEI_VALOR_ACORDADO: '',
    VEI_TIPO: '',
    VEN_ID: VEN_ID,
    CLI_ID: CLI_ID,
  }
  const [dataCarro, setDataCarro] = useState(DEFAULT_DATA)
  const [valorVeiculo, setValorVeiculo] = useState('')
  // const [valorAcordado, setValorAcordado] = useState("");
  const [valorCodigo, setValorCodigo] = useState(false)

  //RENDERIZAÇÃO CONDICIONAL FIPE
  const [marcaVeiVisivel, setMarcaVeiVisivel] = useState(false)
  const [modeloVeiVisivel, setModeloVeiVisivel] = useState(false)
  const [anoVeiVisivel, setAnoVeiVisivel] = useState(false)

  useEffect(() => {
    const fipe = tabelaDeReferenciaHack()
    setReferenciaFipe(fipe)
    if (PLACA && !zeroKM) {
      setDataCarro((prev) => ({ ...prev, VEI_PLACA: PLACA }))
    } else if (zeroKM) {
      setDataCarro((prev) => ({ ...prev, VEI_PLACA: 'ZERO KM' }))
    }
  }, [])

  //DADOS DE ENVIO

  const consultarMarcas = async (seg) => {
    setDataCarro((prev) => ({ ...prev, VEI_TIPO: seg.toString() }))

    try {
      const data = await fipeRepository.consultarMarcas(referenciaFipe, seg)

      var marcas = data.map((e) => {
        return { label: e.Label, value: e.Value }
      })
      setMarcasVei(marcas)
      setMarcaVeiVisivel(true)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setMarcaVeiVisivel(false)
      setModeloVeiVisivel(false)
      setAnoVeiVisivel(false)
      setLoading(false)
    }
    // await axios
    //   .post('https://kaminocrm.com.br:8999/fipe/ConsultarMarcas', {
    //     codigoTabelaReferencia: referenciaFipe,
    //     codigoTipoVeiculo: seg,
    //   })
    //   .then((res) => {
    //     var marcas = res.data.map((e) => {
    //       return { label: e.Label, value: e.Value }
    //     })
    //     setMarcasVei(marcas)
    //     setMarcaVeiVisivel(true)
    //     setLoading(false)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     setMarcaVeiVisivel(false)
    //     setModeloVeiVisivel(false)
    //     setAnoVeiVisivel(false)
    //     setLoading(false)
    //   })
  }

  const consultarModelos = async (marcaSelecionada) => {
    setDataCarro((prev) => ({ ...prev, VEI_CODIGO_MARCA: marcaSelecionada }))

    try {
      const data = await fipeRepository.consultarModelos(
        referenciaFipe,
        dataCarro,
        marcaSelecionada,
      )

      var modelos = data.Modelos.map((e) => {
        return { label: e.Label, value: e.Value }
      })
      setModelosVei(modelos)
      setModeloVeiVisivel(true)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setModeloVeiVisivel(false)
      setAnoVeiVisivel(false)
      setLoading(false)
    }

    // await axios
    //   .post('https://kaminocrm.com.br:8999/fipe/ConsultarModelos', {
    //     codigoTabelaReferencia: referenciaFipe,
    //     codigoTipoVeiculo: Number(dataCarro.VEI_TIPO),
    //     codigoMarca: marcaSelecionada,
    //   })
    //   .then((res) => {
    //     var modelos = res.data.Modelos.map((e) => {
    //       return { label: e.Label, value: e.Value }
    //     })
    //     setModelosVei(modelos)
    //     setModeloVeiVisivel(true)
    //     setLoading(false)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     setModeloVeiVisivel(false)
    //     setAnoVeiVisivel(false)
    //     setLoading(false)
    //   })
  }

  const consultarAnos = async (modeloSelecionado) => {
    setDataCarro((prev) => ({ ...prev, VEI_CODIGO_MODELO: modeloSelecionado }))

    try {
      const data = await fipeRepository.consultarAnoModelo(
        referenciaFipe,
        dataCarro,
        modeloSelecionado,
      )

      var anosModelo = data.map((e) => {
        return { label: e.Label, value: e.Value }
      })
      setAnoModeloVei(anosModelo)
      setAnoVeiVisivel(true)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setAnoVeiVisivel(false)
      setLoading(false)
    }
    // await axios
    //   .post('https://kaminocrm.com.br:8999/fipe/ConsultarAnoModelo', {
    //     codigoTabelaReferencia: referenciaFipe,
    //     codigoTipoVeiculo: Number(dataCarro.VEI_TIPO),
    //     codigoMarca: Number(dataCarro.VEI_CODIGO_MARCA),
    //     codigoModelo: modeloSelecionado,
    //   })
    //   .then((res) => {
    //     var anosModelo = res.data.map((e) => {
    //       return { label: e.Label, value: e.Value }
    //     })
    //     setAnoModeloVei(anosModelo)
    //     setAnoVeiVisivel(true)
    //     setLoading(false)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     setAnoVeiVisivel(false)
    //     setLoading(false)
    //   })
  }

  const consultarComTodosOsParametros = async (anoModeloSelecionado) => {
    anoModeloSelecionado = anoModeloSelecionado.split('-')
    setDataCarro((prev) => ({
      ...prev,
      VEI_CODIGO_ANO_MODELO: anoModeloSelecionado[0],
    }))

    try {
      const data = await fipeRepository.consultarValorTodosParametros(
        referenciaFipe,
        dataCarro,
        anoModeloSelecionado,
      )

      setDataCarro((prev) => ({
        ...prev,
        VEI_MARCA: data?.Marca,
        VEI_MODELO: data?.Modelo,
        VEI_ANO_MODELO: data?.AnoModelo,
        VEI_ANO_FAB: data?.AnoModelo,
        VEI_COD_FIPE: referenciaFipe,
        VEI_COD_TABELA_FIPE: data?.CodigoFipe,
        VEI_VALOR_FIPE: data?.Valor,
      }))
      setValorVeiculo(data.Valor)
      // setValorAcordado(data.Valor);
      setDataCarro((prev) => ({
        ...prev,
        VEI_VALOR_ACORDADO: data.Valor,
      }))
      setValorCodigo(true)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }

    // await axios
    //   .post(
    //     'https://kaminocrm.com.br:8999/fipe/ConsultarValorComTodosParametros',
    //     {
    //       codigoTabelaReferencia: referenciaFipe,
    //       codigoTipoVeiculo: Number(dataCarro.VEI_TIPO),
    //       codigoMarca: dataCarro.VEI_CODIGO_MARCA,
    //       ano: anoModeloSelecionado[0],
    //       codigoTipoCombustivel: anoModeloSelecionado[1],
    //       anoModelo: anoModeloSelecionado[0],
    //       codigoModelo: dataCarro.VEI_CODIGO_MODELO,
    //     },
    //   )
    //   .then((res) => {
    //     setDataCarro((prev) => ({
    //       ...prev,
    //       VEI_MARCA: res.data?.Marca,
    //       VEI_MODELO: res.data?.Modelo,
    //       VEI_ANO_MODELO: res.data?.AnoModelo,
    //       VEI_ANO_FAB: res.data?.AnoModelo,
    //       VEI_COD_FIPE: referenciaFipe,
    //       VEI_COD_TABELA_FIPE: res.data?.CodigoFipe,
    //       VEI_VALOR_FIPE: res.data?.Valor,
    //     }))
    //     setValorVeiculo(res.data.Valor)
    //     // setValorAcordado(res.data.Valor);
    //     setDataCarro((prev) => ({
    //       ...prev,
    //       VEI_VALOR_ACORDADO: res.data.Valor,
    //     }))
    //     setValorCodigo(true)
    //     setLoading(false)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     setLoading(false)
    //   })
  }

  const enviarPlacaFipe = async () => {
    let segmento

    if (dataCarro.VEI_TIPO === '1') {
      segmento = 'CARROS'
    } else if (dataCarro.VEI_TIPO === '2') {
      segmento = 'MOTOS'
    } else if (dataCarro.VEI_TIPO === '3') {
      segmento = 'CAMINHOES'
    }

    const valorAcordadoNumber = String(
      dataCarro.VEI_VALOR_ACORDADO.replace('R$', ''),
      // .replace('.', '')
      // .replace(',', '.'),
    )
    const valorAcordadoFormatado = valorAcordadoNumber.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })

    sessionStorage.setItem('VEI_PLACA', dataCarro.VEI_PLACA)
    sessionStorage.setItem('VEI_VALOR_FIPE', dataCarro.VEI_VALOR_FIPE)
    sessionStorage.setItem('VEI_SEGMENTO', segmento)
    sessionStorage.setItem('VEI_VALOR_ACORDADO', valorAcordadoFormatado)

    const DADOS = {
      VEI_PLACA: dataCarro.VEI_PLACA,
      VEI_MARCA: dataCarro.VEI_MARCA,
      VEI_CODIGO_MARCA: dataCarro.VEI_CODIGO_MARCA,
      VEI_MODELO: dataCarro.VEI_MODELO,
      VEI_ANO_MODELO: `${dataCarro.VEI_ANO_MODELO}`,
      VEI_CODIGO_ANO_MODELO: dataCarro.VEI_CODIGO_ANO_MODELO,
      VEI_CODIGO_MODELO: `${dataCarro.VEI_CODIGO_MODELO}`,
      VEI_ANO_FAB: `${dataCarro.VEI_ANO_FAB}`,
      VEI_COD_TABELA_FIPE: dataCarro.VEI_COD_TABELA_FIPE,
      VEI_COD_FIPE: `${referenciaFipe}`,
      VEI_VALOR_FIPE: dataCarro.VEI_VALOR_FIPE.replace('R$', ''),
      VEI_VALOR_ACORDADO: valorAcordadoNumber,
      VEI_TIPO: segmento,
      VEN_ID: VEN_ID,
      CLI_ID: CLI_ID,
    }

    try {
      const data = await veiculoRepository.criarVeiculo(DADOS)

      sessionStorage.setItem('VEI_ID', data.DADOS.VEI_ID)
      setVEI_MARCA(dataCarro.VEI_MARCA)
      setVEI_MODELO(dataCarro.VEI_MODELO)
      setVEI_ANO(dataCarro.VEI_CODIGO_ANO_MODELO)
      if (segmento === 'CAMINHOES') {
        navigate('/home/cadastro-implementos')
      } else {
        navigate('/home/planos-veiculo')
      }
    } catch (error) {
      console.log('Error ao cadastrar veículo:', error)
    }

    // axios
    //   .post('https://kaminocrm.com.br:7778/veiculos/cadastrarVeiculo', DADOS, {
    //     headers: {
    //       Authorization: `Bearer ${JWT_TOKEN}`,
    //       user_login: LOGIN,
    //       ass_cod: ASS_COD,
    //     },
    //   })
    //   .then((res) => {
    //     sessionStorage.setItem('VEI_ID', res.data.DADOS.VEI_ID)
    //     // console.log('vei_Id', res.data.DADOS.VEI_ID)
    //     setVEI_MARCA(dataCarro.VEI_MARCA)
    //     setVEI_MODELO(dataCarro.VEI_MODELO)
    //     setVEI_ANO(dataCarro.VEI_CODIGO_ANO_MODELO)
    //     if (segmento === 'CAMINHOES') {
    //       navigate('/home/cadastro-implementos')
    //     } else {
    //       navigate('/home/planos-veiculo')
    //     }
    //   })
    //   .catch((err) => console.log(err))
  }

  const buscarPlanosVeiculo = async () => {
    let segmento
    let valor

    if (dataCarro.VEI_TIPO === '1') {
      segmento = 'CARROS'
    } else if (dataCarro.VEI_TIPO === '2') {
      segmento = 'MOTOS'
    } else if (dataCarro.VEI_TIPO === '3') {
      segmento = 'CAMINHOES'
    }

    valor = dataCarro.VEI_VALOR_ACORDADO

    const buscarPlanosSegmento = async () => {
      try {
        const data = await planosRepository.buscarPlanos(
          segmento,
          valor,
          VEN_ID,
        )

        console.log('BUSCANDO PLANOS SEGMENTO', data)

        if (data?.length === 0) {
          MySwal.fire({
            title: 'Atenção!',
            text: 'Não há planos disponíveis para esse veículo.',
            icon: 'warning',
            confirmButtonText: 'Ok',
          })
        } else {
          enviarPlacaFipe()
        }
      } catch (error) {
        console.log(error)
      }
    }

    buscarPlanosSegmento()

    // await axios
    //   .get(
    //     `https://kaminocrm.com.br:7778/planos/buscarPlanos?segmento=${segmento}&preco=${valor}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${JWT_TOKEN}`,
    //         user_login: LOGIN,
    //         ass_cod: ASS_COD,
    //         ven_id: VEN_ID,
    //       },
    //     },
    //   )
    //   .then((res) => {
    //     if (res.data?.length === 0) {
    //       MySwal.fire({
    //         title: 'Atenção!',
    //         text: 'Não há planos disponíveis para esse veículo.',
    //         icon: 'warning',
    //         confirmButtonText: 'Ok',
    //       })
    //     } else {
    //       enviarPlacaFipe()
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }

  return (
    <>
      {loading && <Loading />}
      <BackgroundModal>
        <ModalBoxPlacaFIPE>
          <ModalHeader>
            <h2>Informações do veículo</h2>

            <CloseLink
              to="/home/oportunidades"
              onClick={() => {
                setImplementos([])
                handleClose()
              }}
            >
              <IoMdClose size={17} />
            </CloseLink>
          </ModalHeader>

          {!zeroKM && !noFipe ? (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                margin: '.7rem 0',
                color: '#DA7804',
              }}
            >
              <FiAlertTriangle /> Não encontramos a FIPE automaticamente, por
              favor preencher manualmente.
            </span>
          ) : (
            ''
          )}

          {!noFipe ? (
            <Manual
              dataCarro={dataCarro}
              setDataCarro={setDataCarro}
              zeroKM={zeroKM}
              marcasVei={marcasVei}
              modelosVei={modelosVei}
              anoModeloVei={anoModeloVei}
              segmentos={segmentos}
              valorCodigo={valorCodigo}
              valorVeiculo={valorVeiculo}
              consultarMarcas={consultarMarcas}
              consultarModelos={consultarModelos}
              consultarAnos={consultarAnos}
              marcaVeiVisivel={marcaVeiVisivel}
              modeloVeiVisivel={modeloVeiVisivel}
              anoVeiVisivel={anoVeiVisivel}
              setModeloVeiVisivel={setModeloVeiVisivel}
              setAnoVeiVisivel={setAnoVeiVisivel}
              setValorCodigo={setValorCodigo}
              setLoading={setLoading}
              navigate={navigate}
              buscarPlanosVeiculo={buscarPlanosVeiculo}
              consultarComTodosOsParametros={consultarComTodosOsParametros}
            />
          ) : (
            <Fipe
              dataCarro={dataCarro}
              setDataCarro={setDataCarro}
              zeroKM={zeroKM}
              segmentos={segmentos}
              navigate={navigate}
              buscarPlanosVeiculo={buscarPlanosVeiculo}
            />
          )}
        </ModalBoxPlacaFIPE>
      </BackgroundModal>
    </>
  )
}

export default ModalPlacaFIPE
