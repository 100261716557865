import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  BackgroundModal,
  BtnSubmit,
  ModalBoxCliente,
  BtnRecusar,
  ModalHeader,
  X,
} from '../../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { ButtonEncerrar } from '../../../../Home/HomeRoutes/Oportunidades/Data Table/ResumoTable/styledResumoTable'
import { IoMdClose } from 'react-icons/io'
import Select from 'react-select'
import { MultiSelect } from 'react-multi-select-component'
import ConfirmarArquivar from '../ConfirmarArquivar/ConfirmarArquivar'
import { parametrosRepository } from '../../../../../api/repositories/parametrosRepository'
import { veiculoRepository } from '../../../../../api/repositories/veiculosRepository'

function NovaFiliacao({
  openModal,
  feedback,
  menId,
  setMenId,
  setLoading,
  handleArchive,
}) {
  const DEFAULT_VALUES = {
    MEN_NOME: '',
    MEN_PORCENTAGEM: 0,
    MEN_VEN_IDS: [],
    MEN_TIPO_VEICULO: {},
  }

  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [openArchiveModal, setOpenArchiveModal] = useState(false)
  const [mensalidadeDados, setMensalidadeDados] = useState(DEFAULT_VALUES)
  const [tiposVeiculo, setTiposVeiculo] = useState([])
  const [vendedores, setVendedores] = useState([])

  async function requestsAPI() {
    const buscarTiposVeiculos = async () => {
      try {
        const { tipos } = await veiculoRepository.buscarTiposVeiculos()
        setTiposVeiculo(tipos)
      } catch (error) {
        console.error(error)
      }
    }

    buscarTiposVeiculos()

    /*
    await axios
      .get('https://kaminocrm.com.br:7778/veiculos/buscarTiposDeVeiculos', {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setTiposVeiculo(res.data.tipos)
      })
      .catch((err) => {
        console.log(err)
      })
    */

    /*  await axios
      .get('https://kaminocrm.com.br:7778/parametros/listarVendedores', {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setVendedores(res.data)
      })
      .catch((err) => console.error(err))
    */

    const buscarVendedores = async () => {
      try {
        const vendedores = await parametrosRepository.buscarVendedores()
        setVendedores(vendedores)
      } catch (error) {
        console.error(error)
      }
    }

    buscarVendedores()

    if (menId !== '') {
      setLoading(true)

      const buscarTabelaMensalidade = async () => {
        try {
          const data = await parametrosRepository.buscarTabelaMensalidade(menId)
          console.log('BUSCAR MENSALIDADE', data)
          setMensalidadeDados({
            MEN_NOME: data?.dados[0].MEN_NOME,
            MEN_PORCENTAGEM: Number(data?.dados[0].MEN_PORCENTAGEM),
            MEN_VEN_IDS: data?.dados[0].MEN_VEN_IDS,
            MEN_TIPO_VEICULO: data?.dados[0].MEN_TIPO_VEICULO,
          })
          setLoading(false)
        } catch (error) {
          console.log(error)
          setLoading(false)
          feedback('Ops, algo deu errado!', 'error')
          closeModal()
        }
      }

      buscarTabelaMensalidade()

      /*
      await axios
        .get(
          `https://kaminocrm.com.br:7778/parametros/buscarTabelaDeMensalidade?men_id=${menId}`,
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          },
        )
        .then((res) => {
          setMensalidadeDados({
            MEN_NOME: res.data?.dados[0].MEN_NOME,
            MEN_PORCENTAGEM: Number(res.data?.dados[0].MEN_PORCENTAGEM),
            MEN_VEN_IDS: res.data?.dados[0].MEN_VEN_IDS,
            MEN_TIPO_VEICULO: res.data?.dados[0].MEN_TIPO_VEICULO,
          })
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          feedback('Ops, algo deu errado!', 'error')
          closeModal()
        })
      */
    }
  }

  useEffect(() => {
    requestsAPI()
  }, [])

  const isFormValid = () => {
    const { MEN_NOME, MEN_PORCENTAGEM, MEN_TIPO_VEICULO, MEN_VEN_IDS } =
      mensalidadeDados
    return (
      MEN_NOME !== '' &&
      MEN_PORCENTAGEM !== 0 &&
      Object.keys(MEN_TIPO_VEICULO).length !== 0 &&
      MEN_VEN_IDS.length !== 0
    )
  }

  function closeModal() {
    openModal(false)
    setMenId('')
    setMensalidadeDados(DEFAULT_VALUES)
  }

  async function handleSubmit() {
    if (menId === '') {
      try {
        const data =
          await parametrosRepository.criarTabelaMensalidade(mensalidadeDados)

        console.log('CRIAR TABELA MENSALIDADE: ', data)
        feedback('Desconto criado com sucesso', 'success')
        closeModal()
      } catch (error) {
        console.log(error)
        feedback(error.response.data.message, 'error')
      }
      // await axios
      //   .post(
      //     'https://kaminocrm.com.br:7778/parametros/criarTabelaDeMensalidade',
      //     mensalidadeDados,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${JWT_TOKEN}`,
      //         user_login: LOGIN,
      //         ass_cod: ASS_COD,
      //       },
      //     },
      //   )
      //   .then((res) => {
      //     feedback('Desconto criado com sucesso', 'success')
      //     closeModal()
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //     feedback(err.response.data.message, 'error')
      //   })
    } else {
      const dataEdit = { ...mensalidadeDados, MEN_ID: menId }
      try {
        const data =
          await parametrosRepository.editarTabelaMensalidade(dataEdit)

        console.log('EDITAR TABELA MENSALIDADE:', data)
        feedback('Desconto editado com sucesso', 'success')
        closeModal()
      } catch (error) {
        console.log(error)
        feedback(error.response.data.message, 'error')
      }
      // await axios
      //   .post(
      //     'https://kaminocrm.com.br:7778/parametros/editarTabelaDeMensalidade',
      //     dataEdit,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${JWT_TOKEN}`,
      //         user_login: LOGIN,
      //         ass_cod: ASS_COD,
      //       },
      //     },
      //   )
      //   .then((res) => {
      //     feedback('Desconto editado com sucesso', 'success')
      //     closeModal()
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //     feedback(err.response.data.message, 'error')
      //   })
    }
  }

  return (
    <BackgroundModal>
      {openArchiveModal && (
        <ConfirmarArquivar
          openArchiveModal={setOpenArchiveModal}
          handleArchive={handleArchive}
          menId={menId}
          setLoading={setLoading}
        />
      )}
      <ModalBoxCliente>
        <ModalHeader>
          <h2>Criar novo desconto de mensalidade</h2>
          <X onClick={() => closeModal()}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        <div className="criar--body">
          <div className="criar--inputs">
            <div className="input_container">
              <label htmlFor="descricao_filiacao">Descrição</label>
              <input
                type="text"
                className="input_criar"
                name="descricao_filiacao"
                id="descricao_filiacao"
                value={mensalidadeDados.MEN_NOME}
                onChange={(e) =>
                  setMensalidadeDados((prev) => ({
                    ...prev,
                    MEN_NOME: e.target.value,
                  }))
                }
              />
            </div>
            <div className="input_container">
              <label htmlFor="porcentagem_filiacao">Porcentagem máxima</label>
              <input
                type="text"
                pattern="\d*"
                className="input_criar"
                value={mensalidadeDados.MEN_PORCENTAGEM}
                onChange={(e) => {
                  const { value } = e.target
                  const pattern = /^\d*$/
                  const maxLength = 2

                  if (pattern.test(value) && value.length <= maxLength) {
                    setMensalidadeDados((prev) => ({
                      ...prev,
                      MEN_PORCENTAGEM: Number(e.target.value),
                    }))
                  } else {
                    return
                  }
                }}
              />
            </div>
          </div>
          <div className="criar--inputs">
            <div className="input_container">
              <label htmlFor="">Por vendedores</label>
              <MultiSelect
                options={vendedores}
                value={mensalidadeDados.MEN_VEN_IDS}
                onChange={(e) => {
                  setMensalidadeDados((prev) => ({ ...prev, MEN_VEN_IDS: e }))
                }}
                labelledBy="Selecione..."
                className="multiselect_criar"
                overrideStrings={{
                  allItemsAreSelected: 'Todos os itens selecionados.',
                  clearSearch: 'Limpar pesquisa...',
                  clearSelected: 'Limpar selecionados...',
                  noOptions: 'Sem opções disponíveis...',
                  search: 'Pesquise...',
                  selectAll: 'Selecionar todos',
                  selectAllFiltered: 'Selecionar todos (PESQUISA)',
                  selectSomeItems: 'Selecione...',
                  create: 'Create',
                }}
              />
            </div>
            <div className="input_container">
              <label htmlFor="">Por tipo de veículo</label>
              <Select
                options={tiposVeiculo}
                className="select_criar"
                value={mensalidadeDados.MEN_TIPO_VEICULO}
                placeholder="Selecione..."
                onChange={(e) =>
                  setMensalidadeDados((prev) => ({
                    ...prev,
                    MEN_TIPO_VEICULO: e,
                  }))
                }
              />
            </div>
          </div>
        </div>

        <div className="criar--footer">
          {menId !== '' && (
            <BtnRecusar onClick={() => setOpenArchiveModal(true)}>
              Arquivar filiação
            </BtnRecusar>
          )}
          <BtnSubmit onClick={() => handleSubmit()} disabled={!isFormValid()}>
            Salvar
          </BtnSubmit>
        </div>
      </ModalBoxCliente>
    </BackgroundModal>
  )
}

export default NovaFiliacao
