import React, { useState } from 'react'
import './styles.css'
import Filiacao from './Filiacao/Filiacao'
import CotaParticipacao from './CotaParticipacao/CotaParticipacao'
import Mensalidade from './Mensalidade/Mensalidade'

function TabelaDescontos() {
  const [key, setKey] = useState('filiacao')
  const tabOptions = [
    {
      title: 'Filiação',
      eventKey: 'filiacao',
    },
    {
      title: 'Mensalidade',
      eventKey: 'mensalidade',
    },
    {
      title: 'Cota de Participação',
      eventKey: 'cota_participacao',
    },
  ]

  function renderContent(tab) {
    switch (tab) {
      case 'filiacao':
        return <Filiacao />
      case 'mensalidade':
        return <Mensalidade />
      case 'cota_participacao':
        return <CotaParticipacao />
      default:
        return
    }
  }
  return (
    <div className="tabela_descontos--container">
      <div className="tabela__tabs">
        {tabOptions.map((el) => {
          return (
            <button
              key={el.eventKey}
              className={`${key === el.eventKey ? 'active' : ''}`}
              onClick={() => setKey(el.eventKey)}
            >
              <span>{el.title}</span>
            </button>
          )
        })}
      </div>

      {renderContent(key)}
    </div>
  )
}

export default TabelaDescontos
