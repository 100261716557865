import React from 'react'

function Endor({ defaultColor, size }) {
  return (
    <svg
      className="svgEndor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1168 1041"
      style={{ width: `${size}px`, height: `${size}px` }}
      xmlSpace="preserve"
    >
      <g id="Layer_1">
        <path
          className="st0"
          stroke={defaultColor}
          fill={defaultColor}
          strokeWidth={80}
          strokeMiterlimit={10}
          d="M717.9,937.7L1115,203.5c40.8-75.4-19.5-163.5-111.8-163.5H164.8C70.1,40,10,132.2,55.5,207.8L496.8,942
		C545.2,1022.6,673.3,1020.1,717.9,937.7z"
        />
        <path
          className="st1"
          fill="#fff"
          d="M645.1,629.5L821,304.4c18.1-33.4-8.6-72.4-49.5-72.4H400.2c-41.9,0-68.5,40.9-48.4,74.3l195.4,325.1
		C568.7,667.1,625.4,666,645.1,629.5z"
        />
      </g>
      <g id="Layer_2" />
    </svg>
  )
}

export default Endor
