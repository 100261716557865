import React, { useContext } from 'react'

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom'

import { AuthProvider, AuthContext } from '../context/auth'
import CadastroAssociadoProvider from '../context/CadastroASSContext'
import CadastroVeiculoProvider from '../context/CadastroVEIContext'
import CadastroPlanoProvider from '../context/CadastroPLAContext'
import VistoriaContextProvider from '../context/VistoriaContext'
import KanbanContextProvider from '../context/KanbanContext'
import EmpresaCadastroContextProvider from '../context/EmpresaCadastroContext'
import SiteContextProvider from '../context/SiteContext'

import Inicio from '../pages/Home/HomeRoutes/Inicio/Inicio'
import LoginForm from '../pages/Login/LoginForm'
import Login from '../pages/Login/Login'
import HomePage from '../pages/Home/HomePage'
import ErrorPage from '../pages/Error/ErrorPage'
import Oportunidades from '../pages/Home/HomeRoutes/Oportunidades/Oportunidades'
import ModalCliente from '../pages/Home/HomeRoutes/Oportunidades/Modal/ModalCliente'
import ModalImplementos from '../pages/Home/HomeRoutes/Oportunidades/Modal/ModalImplementos'
import ModalPlaca from '../pages/Home/HomeRoutes/Oportunidades/Modal/ModalPlaca'
import ModalPlacaFIPE from '../pages/Home/HomeRoutes/Oportunidades/Modal/ModalPlacaFIPE'
import ModalPlanos from '../pages/Home/HomeRoutes/Oportunidades/Modal/ModalPlanos'
import ModalResumo from '../pages/Home/HomeRoutes/Oportunidades/Modal/ModalResumo'
import Relatorios from '../pages/Home/HomeRoutes/Relatorios/Relatorios'
import MinhaConta from '../pages/Home/HomeRoutes/Minha Conta/MinhaConta'
import Perfil from '../pages/Home/HomeRoutes/Perfil/Perfil'
import Usuarios from '../pages/Usuarios/Usuarios'
import CadastroUsuarios from '../pages/Usuarios/CadastroUsuarios/CadastroUsuarios'
import Parametros from '../pages/Parametros/Parametros'
import Ajustes from '../pages/Ajustes/Ajustes'
import Onboarding from '../pages/Onboarding/Onboarding.jsx'
import CodigoAssociacao from '../pages/Onboarding/CodigoAssociacao/CodigoAssociacao.jsx'
import RegistroUsuario from '../pages/Onboarding/RegistroUsuario/RegistroUsuario'
import ConclusaoCadastro from '../pages/Onboarding/ConclusaoCadastro/ConclusaoCadastro'

const AppRoutes = () => {
  const Private = ({ children }) => {
    const { authenticated } = useContext(AuthContext)
    if (!authenticated) {
      return <Navigate to="/" />
    }

    return children
  }

  const PasswordCheck = ({ children }) => {
    var location = useLocation()
    const pw = sessionStorage.getItem('ALTERAR_SENHA')
    if (pw === 'true') {
      if (location.pathname !== '/home/dashboard') {
        return <Navigate to="/home/dashboard" />
      }
    }
    return children
  }

  return (
    <Router>
      <EmpresaCadastroContextProvider>
        <AuthProvider>
          <SiteContextProvider>
            <CadastroAssociadoProvider>
              <CadastroVeiculoProvider>
                <CadastroPlanoProvider>
                  <VistoriaContextProvider>
                    <KanbanContextProvider>
                      <Routes>
                        <Route
                          path="/"
                          element={<Navigate to="/onboarding/" replace />}
                        />

                        <Route path="/:empresaCodigo" element={<Login />} />

                        <Route
                          path="home"
                          element={
                            <Private>
                              <PasswordCheck>
                                <HomePage />
                              </PasswordCheck>
                            </Private>
                          }
                        >
                          <Route path="dashboard" element={<Inicio />} />
                          <Route
                            path="oportunidades"
                            element={<Oportunidades />}
                          >
                            <Route
                              path="cadastro-cliente"
                              element={<ModalCliente />}
                            />
                          </Route>
                          <Route
                            path="cadastro-placa"
                            element={<ModalPlaca />}
                          />
                          <Route
                            path="cadastro-placa-fipeFalse"
                            element={<ModalPlacaFIPE />}
                          />
                          <Route
                            path="cadastro-implementos"
                            element={<ModalImplementos />}
                          />
                          <Route
                            path="planos-veiculo"
                            element={<ModalPlanos />}
                          />
                          <Route
                            path="oportunidade-resumo"
                            element={<ModalResumo />}
                          />

                          {/* <Route path='relatorios' element={<Relatorios />} /> */}
                          {/* <Route path='minha-conta' element={<MinhaConta />} /> */}
                          {/* <Route path='perfil' element={<Perfil />} /> */}
                        </Route>

                        <Route path="onboarding" element={<Onboarding />} />
                        <Route
                          path="onboarding/cadastro-codigo"
                          element={<CodigoAssociacao />}
                        />
                        <Route
                          path="onboarding/registro-usuario"
                          element={<RegistroUsuario />}
                        />
                        <Route
                          path="onboarding/conclusao-cadastro"
                          element={<ConclusaoCadastro />}
                        />

                        {/* <Route
                          path='usuarios'
                          element={
                            <Private>
                              <PasswordCheck>
                                <Usuarios />
                              </PasswordCheck>
                            </Private>
                          }
                        >
                          <Route
                            path='cadastro-usuarios'
                            element={
                              <Private>
                                <PasswordCheck>
                                  <CadastroUsuarios />
                                </PasswordCheck>
                              </Private>
                            }
                          />
                        </Route> */}

                        <Route
                          path="parametros-sistema"
                          element={
                            <Private>
                              <PasswordCheck>
                                <Parametros />
                              </PasswordCheck>
                            </Private>
                          }
                        />

                        <Route path="*" element={<ErrorPage />} />
                      </Routes>
                    </KanbanContextProvider>
                  </VistoriaContextProvider>
                </CadastroPlanoProvider>
              </CadastroVeiculoProvider>
            </CadastroAssociadoProvider>
          </SiteContextProvider>
        </AuthProvider>
      </EmpresaCadastroContextProvider>
    </Router>
  )
}

export default AppRoutes
