import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  BackgroundModal,
  ModalBoxPlanos,
  ModalHeader,
  X,
} from '../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import {
  Container,
  Possibilidades,
  StepWrapper,
  Step,
  StepProgress,
  StepBox,
  AddStep,
} from './style/styledConfig'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../../Loading/Loading'
import { IoMdClose, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { ButtonSalvarSenha } from '../../../Home/HomeRoutes/Perfil/styledPerfil'
import { FaPlus } from 'react-icons/fa'
import { RiDeleteBin6Fill } from 'react-icons/ri'
import { AiFillEdit } from 'react-icons/ai'
import { SelectFiltroOpo } from '../../../Home/HomeRoutes/Relatorios/styledRelatorios'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'

function Configuracoes({ openModal, FUN_ID }) {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [steps, setSteps] = useState([])
  const [etapaPadrao, setEtapaPadrao] = useState({})
  const [loading, setLoading] = useState(true)
  const [add, setAdd] = useState(false)
  const [novoNome, setNovoNome] = useState('')
  const [editNovoNome, setEditNovoNome] = useState('')
  const [edit, setEdit] = useState(false)
  // const [excel, setExcel] = useState({})

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  useEffect(async () => {
    try {
      const data = await parametrosRepository.buscarDadosFunil(FUN_ID)

      setSteps(data)
      const etapa = data.find((e) => {
        return (
          e.KANBAN_NOME.toUpperCase() == data[0]?.FUN_KANBAN_NOME.toUpperCase()
        )
      })
      setEtapaPadrao({
        KANBAN_NOME: etapa?.KANBAN_NOME,
        KANBAN_ID: etapa?.KANBAN_ID,
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    //   axios
    //     .get(
    //       `https://kaminocrm.com.br:7778/parametros/buscarDadosFunil/${FUN_ID}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${JWT_TOKEN}`,
    //           user_login: LOGIN,
    //           ass_cod: ASS_COD,
    //         },
    //       },
    //     )
    //     .then((res) => {
    //       // console.log(res);
    //       setSteps(res.data)
    //       const etapa = res.data.find((e) => {
    //         return (
    //           e.KANBAN_NOME.toUpperCase() ==
    //           res.data[0]?.FUN_KANBAN_NOME.toUpperCase()
    //         )
    //       })
    //       setEtapaPadrao({
    //         KANBAN_NOME: etapa?.KANBAN_NOME,
    //         KANBAN_ID: etapa?.KANBAN_ID,
    //       })
    //       setLoading(false)
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //       setLoading(false)
    //     })
  }, [])

  async function enviarSteps(steps, etapaPadrao) {
    // console.log(steps)/
    const dataSend = {
      funis: steps,
      padrao:
        etapaPadrao.KANBAN_NOME !== undefined
          ? etapaPadrao
          : {
              KANBAN_NOME: steps[0].KANBAN_NOME,
              KANBAN_ID: steps[0].KANBAN_ID ? steps[0].KANBAN_ID : '',
            },
    }

    try {
      const data = await parametrosRepository.atualizarOrdemFunil(
        FUN_ID,
        dataSend,
      )

      console.log('ATUALIZANDO ORDEM FUNIL:', data)
      feedback('Etapas atualizadas com sucesso!', 'success')
      setTimeout(function () {
        setLoading(false)
      }, 1800)
      if (!loading) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
      feedback('Ops! Algo deu errado.', 'error')
      setLoading(false)
    }

    /*
    axios
      .post(
        `https://kaminocrm.com.br:7778/parametros/atualizarOrdemFunil/${FUN_ID}`,
        dataSend,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        },
      )
      .then((res) => {
        console.log(res)
        feedback('Etapas atualizadas com sucesso!', 'success')
        setTimeout(function () {
          setLoading(false)
        }, 1800)
        if (!loading) {
          window.location.reload()
        }
      })
      .catch((err) => {
        console.log(err)
        feedback('Ops! Algo deu errado.', 'error')
        setLoading(false)
      })
    */
  }

  useEffect(() => {
    console.log(steps)
  }, [steps])

  function newStep(titulo, pos) {
    console.log(titulo, pos)
    if (steps.length > 0) {
      pos = steps[pos - 1]['ORDER'] + 0.01
    }

    var item = {
      KANBAN_COLOR: '#605C5C',
      KANBAN_NOME: titulo,
      KANBAN_PADRAO: false,
      KANBAN_TITULO: titulo,
      ORDER: pos,
    }

    const isFound = steps.some((element) => {
      if (element.KANBAN_NOME === titulo) {
        return true
      } else {
        return false
      }
    })

    if (isFound) {
      return
    } else {
      setSteps([...steps, item])
    }

    // disable(item, steps);
  }

  function onMove(array, originIndex, toIndex, side) {
    const element = array.splice(originIndex, 1)[0]
    console.log(element)
    var newSteps = [...steps]

    newSteps.splice(toIndex, 0, element)
    var orderUpdate
    var nextItem = newSteps[originIndex]
    var prevItem = newSteps[originIndex - 1]

    if (side === 'esquerda') {
      orderUpdate = prevItem.ORDER + 0.01
      if (!nextItem.KANBAN_PADRAO) {
        var newOrderPrev = nextItem.ORDER + 0.01
        orderUpdate = element.ORDER - 0.01
        nextItem.ORDER = Number(newOrderPrev.toFixed(2))
      }
    } else if (side === 'direita') {
      orderUpdate = nextItem.ORDER + 0.01
    }

    element.ORDER = Number(orderUpdate.toFixed(2))
    if (toIndex !== -1) {
      newSteps[toIndex] = element
    }
    // disable(element, newSteps, side, prevItem, nextItem);
    setSteps(newSteps)
  }

  function updateItem(originIndex, name) {
    var stepsCopy = [...steps]
    const element = steps.splice(originIndex, 1)[0]
    element.KANBAN_TITULO = name
    element.KANBAN_NOME = name
    if (originIndex !== -1) {
      stepsCopy[originIndex] = element
    }
    console.log(stepsCopy)
    setSteps(stepsCopy)
  }

  function deleteItem(originIndex) {
    var stepsCopy = [...steps]
    stepsCopy.splice(originIndex, 1)
    setSteps(stepsCopy)
  }

  // function enviarExcel (ev){
  //   var ex = ev.target.files[0];
  //   setExcel(ex)

  // }

  // function importarExcel(){
  //   var data = new FormData();
  //   data.append('FILE', excel);
  //   data.append('FUN_ID', FUN_ID);

  //   axios.post("https://kaminocrm.com.br:7778/oportunidades/cadastroLeads", data,
  //   {
  //     maxBodyLength: Infinity,
  //     headers: {
  //       Authorization: `Bearer ${JWT_TOKEN}`,
  //       user_login: LOGIN,
  //       ass_cod: ASS_COD,
  //     },
  //   })
  //   .then((res) => {
  //     console.log(res)
  //     setLoading(false)
  //   })
  //   .catch((err) => {
  //     console.log(err)
  //     setLoading(false)
  //   })
  // }

  function handleSubmit() {
    enviarSteps(steps, etapaPadrao)
    // if(excel){
    //   importarExcel()
    // }
  }

  return (
    <BackgroundModal>
      <ToastContainer />
      {loading && <Loading />}
      <ModalBoxPlanos>
        <ModalHeader>
          <h2>Configurações do funil</h2>
          <X onClick={() => openModal(false)}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>
        <div className="modalBody">
          <div className="flex_label">
            <label>Gerenciar etapas</label>
          </div>
          <Container>
            <StepWrapper>
              {steps.map((i, index) => {
                return (
                  <Step key={i.KANBAN_NOME}>
                    <StepProgress>
                      {!i.KANBAN_PADRAO && (
                        <div>
                          <div className="arrows">
                            {index !== 0 ? (
                              <button
                                className="able"
                                onClick={() => {
                                  const fromIndex = steps.indexOf(i)
                                  const toIndex = fromIndex - 1
                                  onMove(steps, fromIndex, toIndex, 'esquerda')
                                }}
                              >
                                <IoIosArrowBack />
                              </button>
                            ) : (
                              <button className="not_able">
                                <IoIosArrowBack />
                              </button>
                            )}
                            {index !== steps.length - 1 ? (
                              <button
                                className="able"
                                onClick={() => {
                                  const fromIndex = steps.indexOf(i)
                                  const toIndex = fromIndex + 1
                                  onMove(steps, fromIndex, toIndex, 'direita')
                                }}
                              >
                                <IoIosArrowForward />
                              </button>
                            ) : (
                              <button className="not_able">
                                <IoIosArrowForward />
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      <input
                        type="checkbox"
                        name="ball"
                        id="ball"
                        className="ball"
                      />
                      <div className="line" />
                    </StepProgress>
                    <StepBox>
                      <div className="titulo">
                        <p>{i.KANBAN_TITULO}</p>
                      </div>

                      {!i.KANBAN_PADRAO ? (
                        <>
                          <div className="acoes">
                            <button
                              className="editar"
                              onClick={() => {
                                setEdit(!edit)
                                setEditNovoNome(i.KANBAN_TITULO)
                                setNovoNome(i.KANBAN_TITULO)
                                setAdd(false)
                              }}
                            >
                              <AiFillEdit /> <span>Editar</span>
                            </button>

                            {!i.KANBAN_PADRAO && !i.KANBAN_EDITAVEL ? (
                              <button
                                onClick={() => {
                                  const fromIndex = steps.indexOf(i)
                                  deleteItem(fromIndex)
                                }}
                                className="btn_excluir"
                              >
                                <RiDeleteBin6Fill size={13} />
                              </button>
                            ) : (
                              ''
                            )}
                          </div>

                          {edit && novoNome === i.KANBAN_TITULO ? (
                            <div>
                              <label htmlFor="edit_nome">Novo nome</label>
                              <input
                                type="text"
                                value={editNovoNome}
                                name="edit_nome"
                                id="edit_nome"
                                style={{ textTransform: 'none' }}
                                onChange={(e) => {
                                  setEditNovoNome(e.target.value)
                                }}
                              />
                              <button
                                className="btn_salvar"
                                onClick={() => {
                                  const fromIndex = steps.indexOf(i)
                                  updateItem(fromIndex, editNovoNome)
                                  setEdit(false)
                                }}
                              >
                                Salvar
                              </button>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </StepBox>
                  </Step>
                )
              })}
              <AddStep className={add ? 'open' : ''}>
                <StepProgress>
                  <button className="add__button" onClick={() => setAdd(!add)}>
                    <FaPlus />
                  </button>
                  <div className="line_button" />
                </StepProgress>
                {add && (
                  <StepBox>
                    <label htmlFor="itemnovo">Nova etapa</label>
                    <input
                      type="text"
                      name="itemnovo"
                      id="itemnovo"
                      style={{ textTransform: 'none' }}
                      value={novoNome}
                      onChange={(e) => setNovoNome(e.target.value)}
                    />

                    <button
                      className="adicionar"
                      onClick={() => {
                        newStep(novoNome, steps.length)
                        setNovoNome('')
                      }}
                    >
                      Adicionar etapa
                    </button>
                  </StepBox>
                )}
              </AddStep>
            </StepWrapper>
          </Container>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "5px",
            }}
          >
            <ButtonSalvarSenha
              onClick={() => {
                setLoading(true);
                enviarSteps(steps);
              }}
            >
              Salvar mudanças de etapa
            </ButtonSalvarSenha>
          </div> */}

          {steps.length > 0 && (
            <>
              <label
                htmlFor="sendXls"
                style={{ display: 'block', marginBottom: '0.5rem' }}
              >
                Etapa inicial de nova oportunidade
              </label>
              <div style={{ display: 'block' }}>
                <SelectFiltroOpo
                  name="steps"
                  id="steps"
                  value={JSON.stringify(etapaPadrao)}
                  onChange={(e) => setEtapaPadrao(JSON.parse(e.target.value))}
                >
                  {steps.map((el) => {
                    var opt = {
                      KANBAN_NOME: el.KANBAN_NOME,
                      KANBAN_ID: el.KANBAN_ID ? el.KANBAN_ID : '',
                    }
                    return (
                      <option value={JSON.stringify(opt)}>
                        {el.KANBAN_NOME.toUpperCase()}
                      </option>
                    )
                  })}
                </SelectFiltroOpo>
              </div>
            </>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '5px',
            }}
          >
            <ButtonSalvarSenha
              onClick={() => {
                setLoading(true)
                handleSubmit()
              }}
              disabled={steps.length == 0}
            >
              Salvar alterações
            </ButtonSalvarSenha>
          </div>
        </div>
      </ModalBoxPlanos>
    </BackgroundModal>
  )
}

export default Configuracoes
