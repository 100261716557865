import React, { useState, useEffect } from 'react'
import { BtnSubmit } from '../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal'
import { FaPlus } from 'react-icons/fa'
import { MdUnarchive, MdEdit } from 'react-icons/md'
import NovaMensalidade from './NovaMensalidade/NovaMensalidade'
import { TableButton } from '../../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable'
import { ToastContainer, toast } from 'react-toastify'
import Loading from '../../../Loading/Loading'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import axios from 'axios'
import { COLORS } from '../../../../colors'
import ConfirmarDesarquivar from './ConfirmarDesarquivar/ConfirmarDesarquivar'
import { parametrosRepository } from '../../../../api/repositories/parametrosRepository'

function Mensalidade() {
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [openModal, setOpenModal] = useState(false)
  const [openArchiveModal, setOpenArchiveModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [dataList, setDataList] = useState([])
  const [menId, setMenId] = useState('')

  useEffect(() => {
    ;(async () => {
      const listarTabelaMensalidade = async () => {
        try {
          const data = await parametrosRepository.listarTabelaMensalidade()
          console.log('TABELA MENSALIDADE', data?.dados)
          setDataList(data?.dados)
          setLoading(false)
        } catch (error) {
          console.log(error)
          setLoading(false)
        }
      }

      listarTabelaMensalidade()

      /*
      axios
        .get(
          'https://kaminocrm.com.br:7778/parametros/listarTabelaDeMensalidade',
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          },
        )
        .then((res) => {
          setDataList(res.data?.dados)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })

        */
    })()
  }, [openModal, openArchiveModal])

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (type === 'warn') {
      toast.warn(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      toast.info('Internal server error', {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  async function handleArchive(men_id) {
    try {
      const data =
        await parametrosRepository.arquivarOuDesarquivarTabelaMensalidade(
          men_id,
        )

      console.log('ARQUIVAR OU DESARQUIVAR TABELA MENSALIDADE', data)

      setOpenModal(false)
      setOpenArchiveModal(false)
      feedback(data?.message, 'success')
    } catch (error) {
      console.log(error)
    }
    // await axios
    //   .post(
    //     `https://kaminocrm.com.br:7778/parametros/arquivarEDesarquivarTabelaDeMensalidade/${men_id}`,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${JWT_TOKEN}`,
    //         user_login: LOGIN,
    //         ass_cod: ASS_COD,
    //       },
    //     },
    //   )
    //   .then((res) => {
    //     setOpenModal(false)
    //     setOpenArchiveModal(false)
    //     feedback(res.data?.message, 'success')
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     // setOpenArchiveModal(false)
    //   })
  }

  function checarStatus(MEN_ATIVO) {
    if (MEN_ATIVO) {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.pendente}`,
            padding: '2px 10px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ATIVO
        </span>
      )
    } else {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.perdida}`,
            padding: '2px 7px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ARQUIVADO
        </span>
      )
    }
  }

  return (
    <>
      {loading && <Loading />}
      {openArchiveModal && (
        <ConfirmarDesarquivar
          openArchiveModal={setOpenArchiveModal}
          handleArchive={handleArchive}
          menId={menId}
          setMenId={setMenId}
        />
      )}
      {openModal && (
        <NovaMensalidade
          openModal={setOpenModal}
          feedback={feedback}
          menId={menId}
          setMenId={setMenId}
          loading={loading}
          setLoading={setLoading}
          handleArchive={handleArchive}
        />
      )}
      {/* <ToastContainer /> */}
      <div className="criar">
        <BtnSubmit
          onClick={() => {
            setMenId('')
            setOpenModal(true)
          }}
        >
          <FaPlus size={11} /> <span>Nova mensalidade</span>
        </BtnSubmit>
      </div>
      <h3>Gerenciar descontos de mensalidade</h3>

      <div className="table_container">
        <table className="table">
          <thead>
            <tr>
              <th>Descrição</th>
              <th>% (Máx.)</th>
              <th>Tipo de Veículo</th>
              <th>Status</th>
              <th className="col_config">Ações</th>
            </tr>
          </thead>
          <tbody>
            {dataList?.map((el) => {
              return (
                <tr key={el.MEN_ID}>
                  <td>{el.MEN_NOME.toUpperCase()}</td>
                  <td>{el.MEN_PORCENTAGEM}%</td>
                  <td>{el.MEN_TIPO_VEICULO}</td>
                  <td>{checarStatus(el.MEN_ATIVO)}</td>
                  <td className="col_config">
                    {el.MEN_ATIVO ? (
                      <Tippy
                        content="Editar desconto"
                        delay="275"
                        placement="bottom"
                      >
                        <TableButton
                          onClick={() => {
                            setLoading(true)
                            setMenId(el.MEN_ID)
                            setOpenModal(true)
                          }}
                        >
                          <MdEdit size={20} />
                        </TableButton>
                      </Tippy>
                    ) : (
                      <Tippy
                        content="Desarquivar desconto"
                        delay="275"
                        placement="bottom"
                      >
                        <TableButton
                          onClick={() => {
                            setMenId(el.MEN_ID)
                            setOpenArchiveModal(true)
                          }}
                        >
                          <MdUnarchive size={22} />
                        </TableButton>
                      </Tippy>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Mensalidade
